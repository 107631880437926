import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Input, Modal, Select, DatePicker, Tooltip } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import { PAYROLL_ADVANCE_SALARY_APPROVAL } from "constants";
import { emiEnum } from "components/common/enum";
import { EmployeeDetails } from "components/common/CommonComponent";
import { validateNumericMandatory, disabledPastDate, extractImageName} from "components/common/validation";
import locale from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import { CommonCarouselPopup } from "components/common/CommonComponent";
import { EyeOutlined } from "@ant-design/icons";
import IconButton from "components/ui/Button/IconButton"

function EditPayrollAdvanceSalary({ fromEdit, editDetails, onHide, onSave, isEdit }) {
    const authtoken = sessionStorage.getItem("token");
    const prevPropsRef = useRef();
    const [formData, setFormData] = useState({
        emi_period: undefined,
        approved_amount: undefined,
        approval_notes: undefined,
        reason: undefined,
        advance_requested: undefined,
        debit_from: undefined,
        requested_emi_period: undefined,
        uploaded_documents: [],
    });
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const hideCursorRef = useRef(null);
    const [isImgOpen, setImgOpen] = useState(false);
    const dateFormat = "YYYY-MM-DD";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            let documentList = (editDetails.uploaded_documents && editDetails.uploaded_documents?.length > 0) ?
                editDetails.uploaded_documents.map(url => ({
                    name: extractImageName(url),
                    url: url
                })) : [];
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.advance_salary_id,
                emi_period: editDetails.emi_period ? editDetails.emi_period : null,
                reason: editDetails.reason ? editDetails.reason : null,
                advance_requested: editDetails.advance_requested ? editDetails.advance_requested : null,
                approved_amount: editDetails?.request_type === "cancel request" ? (editDetails.advance_approved ? editDetails.advance_approved : null) : (editDetails.advance_requested ? editDetails.advance_requested : null),
                requested_emi_period: editDetails.emi_period ? editDetails.emi_period : null,
                uploaded_documents: documentList,
                debit_from: editDetails.debit_from ? dayjs(editDetails.debit_from, dateFormat) : null,
            }));
            form.setFieldsValue({
                emi_period: editDetails.emi_period ? editDetails.emi_period : null,
                reason: editDetails.reason ? editDetails.reason : null,
                advance_requested: editDetails.advance_requested ? editDetails.advance_requested : null,
                approved_amount: editDetails?.request_type === "cancel request" ? (editDetails.advance_approved ? editDetails.advance_approved : null) : (editDetails.advance_requested ? editDetails.advance_requested : null),
                requested_emi_period: editDetails.emi_period ? editDetails.emi_period : null,
                uploaded_documents: documentList,
                debit_from: editDetails.debit_from ? dayjs(editDetails.debit_from, dateFormat) : null
            });
            prevPropsRef.current = editDetails;
        }
    }, [editDetails])

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        onHide();
        setFormData({
            emi_period: undefined,
            approved_amount: undefined,
            approval_notes: undefined,
            reason: undefined,
            advance_requested: undefined,
            debit_from: undefined,
            requested_emi_period: undefined,
            uploaded_documents: [],
        });
    };

    const handleSubmit = useCallback((isRejectClked) => {
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoading(true)
            let updatedFormData = { 
                ...formData,
                debit_from: formData.debit_from ? dayjs(formData.debit_from, 'DD-MM-YYYY').format('YYYY-MM-DD') : null, 
                request_type: editDetails?.request_type ? editDetails?.request_type : undefined
            };
            if (isRejectClked && editDetails?.request_type === "cancel request") {
                updatedFormData = { ...updatedFormData, request_type: "advance request" }
            } else {
                updatedFormData.request_type = editDetails?.request_type ? editDetails?.request_type : undefined
            }
            updatedFormData.uploaded_documents = updatedFormData?.uploaded_documents?.length > 0 ? updatedFormData.uploaded_documents.map(image => (image.url)) : [];
            if (isRejectClked) {
                updatedFormData = { ...updatedFormData, approval_status:  editDetails?.request_type === "cancel request" ? "approved" : 'rejected' };
            } else {
                updatedFormData = { ...updatedFormData, approval_status:  editDetails?.request_type === "cancel request" ? "cancelled" : 'approved' };
            }
            fetch(PAYROLL_ADVANCE_SALARY_APPROVAL, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        onSave();
                        handleCancel();
                        setLoading(false);
                        setFormData({
                            emi_period: undefined,
                            approved_amount: undefined,
                            approval_notes: undefined,
                            reason: undefined,
                            advance_requested: undefined,
                            debit_from: undefined,
                            requested_emi_period: undefined,
                            uploaded_documents: [],
                        })
                    }
                    else if (data.status === "fail") {
                        setLoading(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [formData, fromEdit, handleCancel, onSave]);


    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSave = useCallback(() => {
        handleSubmit(false);
    }, [handleSubmit]);

    const handleReject = useCallback(() => {
        handleSubmit(true);
    }, [handleSubmit]);

    let empDetails = {
        ...editDetails.employee,
        "designation_id": editDetails?.employee.designation ? { id: editDetails?.employee.designation.id, designation_name: editDetails?.employee.designation.name } : { id: 0, designation_name: "" },
        "first_name": editDetails?.employee.employee_name ? editDetails?.employee.employee_name : "",
        "id": editDetails?.employee.employee_id
    }

    const handlePreviewImage = (e) => {
        e.preventDefault();
        if (formData?.uploaded_documents.length > 0) {
          setImgOpen(true);
        }
      };
    
      const handlePreviewCancel = () => {
        setImgOpen(false);
      };
    
    return (
        <Modal
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={"30vw"}
        >
            <div div className="w-full">
                <p id="common_ApprovalTxt">{"Advance Salary"}</p>

                <div className="my-4">
                    <p style={styles.docTxt}>{"Employee Details"}</p>
                    <EmployeeDetails details={empDetails} />
                </div>
                <Form
                    layout="vertical"
                    form={form}
                    name="basicform"
                    onFinish={handleSave}
                    onSubmit={(e) => e.preventDefault()}
                    colon={false}
                    requiredMark={false}
                    style={{ width: "100%" }}
                    initialValues={true}
                >
                    <div className="grid grid-cols-1" style={{height:"55vh", overflowY:"scroll", padding:"0.5vw", scrollbarWidth:"thin", marginBottom:"0.75vw"}}>
                        <div className="grid grid-cols-2 gap-4">
                            <Form.Item
                                label="Requested Amount"
                                name="advance_requested"
                            >
                                <Input
                                    autoComplete='off'
                                    style={{ width: "100%" }}
                                    type={"text"}
                                    name={"advance_requested"}
                                    maxLength={7}
                                    value={formData.advance_requested ? formData.advance_requested : ""}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    data-testid={''}
                                    disabled={fromEdit ? 'true' : 'false'}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Approval Amount"
                                name="approved_amount"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.reject(new Error('This field is required'));
                                            }
                                            if (parseFloat(value) <= 0) {
                                                return Promise.reject(new Error('Enter a valid amount'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                tooltip={{
                                  title: "This field is required",
                                  icon: <span style={{ color: "red" }}>*</span>,
                                }}
                            >
                                <Input
                                    autoComplete='off'
                                    style={{ width: "100%" }}
                                    type={"number"}
                                    name={"approved_amount"}
                                    value={formData.approved_amount ? formData.approved_amount : ""}
                                    maxLength={7}
                                    onChange={(e) => handleChange(e.target.name, +e.target.value)}
                                    data-testid={''}
                                    disabled= {editDetails.request_type === "cancel request"}
                                />
                            </Form.Item>
                        </div>
                        <div className="grid gap-4 grid-cols-2">
                            <Form.Item label="Requesed EMI Period" name="requested_emi_period">
                                <Input
                                    autoComplete='off'
                                    style={{ width: "100%" }}
                                    type={"text"}
                                    name={"emi_period"}
                                    value={formData.requested_emi_period ? formData.requested_emi_period : ""}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    data-testid={''}
                                    disabled={fromEdit ? 'true' : 'false' || editDetails.request_type === "cancel request"}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Approval EMI Period"
                                name="emi_period"
                                rules={[{ required: true, message: "This field is required", validator: validateNumericMandatory }]}
                                tooltip={{
                                  title: "This field is required",
                                  icon: <span style={{ color: "red" }}>*</span>,
                                }}
                            >
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    onChange={(value) => handleChange("emi_period", value)}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={formData.emi_period ? formData.emi_period : ""}
                                    placeholder="Select Requesed EMI Period"
                                    ref={hideCursorRef}
                                    disabled= {editDetails.request_type === "cancel request"}
                                    onSelect={() => {
                                        hideCursorRef.current.blur();
                                    }} 
                                >
                                    {emiEnum.map((option) => (
                                        <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="grid gap-4 grid-cols-2">
                            <Form.Item
                                label="Debit From"
                                name="debit_from"
                                rules={[{ required: true, message: "This field is required" }]}
                                tooltip={{
                                    title: "This field is required",
                                    icon: <span style={{ color: "red" }}>*</span>,
                                }}>
                                <DatePicker
                                    allowClear
                                    inputReadOnly={true}
                                    name={"debit_from"}
                                    value={formData.debit_from ? formData.debit_from : ""}
                                    format={"DD-MM-YYYY"}
                                    style={{ width: "100%" }}
                                    locale={locale}
                                    onChange={(date) => handleChange("debit_from", date)}
                                    disabledDate={disabledPastDate}
                                    placeholder = ""
                                    disabled= {editDetails.request_type === "cancel request"}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Reason"
                                name="reason"
                            >
                                <Input.TextArea
                                    bordered="true"
                                    className="mt-2 textarea"
                                    rows={4}
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 5,
                                    }}
                                    maxLength = {150}
                                    placeholder="Reasons for applying for an advance salary"
                                    name="reason"
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            reason: e.target.value,
                                        });
                                    }}
                                    disabled={fromEdit ? 'true' : 'false'}
                                />
                            </Form.Item>
                        </div>
                        {formData.uploaded_documents?.length > 0 ?
                            <div className="grid grid-cols-2 gap-x-3 gap-y-2 mt-2">
                                {formData.uploaded_documents.map((file, index) => (
                                    <div key={index} className="flex justify-between items-center border-b" style={{ paddingInline: "0.3dvw" }}>
                                        {file?.name?.length > 15 ?
                                            <Tooltip title={file?.name}>{<span style={{ fontSize: "0.8vw" }}>{"..." + file?.name?.slice(-14)}</span>}</Tooltip>
                                            : <p style={{ fontSize: "0.8vw" }}>{file?.name ? file?.name : "-"}</p>}
                                        <div>
                                            <IconButton
                                                title="View"
                                                icon={<EyeOutlined style={styles.closeIcon} />}
                                                onClick={handlePreviewImage}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            : null}
                         <div>
                            <Form.Item
                                label="Approval Notes"
                                name="approval_notes"
                            >
                                <Input.TextArea
                                    autoComplete='off'
                                    bordered="true"
                                    className="mt-2 textarea"
                                    rows={4}
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 5,
                                    }}
                                    maxLength = {150}
                                    placeholder="Enter reason for Approval Notes for a Advance salary"
                                    name="approval_notes"
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            approval_notes: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </div> 
                    </div>
                    {isEdit === true ? <Form.Item>
                        <div className='flex justify-end items-end'>
                            <MyButton htmlType="button" label={"Reject"} onClick={handleReject} bgColor={"#D94854"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='asgreg_btn_draft' />
                            <MyButton htmlType="submit" label={"Approve"} loading={loading} paddingX={"0 1.2vw"} bgColor={"#0E862A"} />
                        </div>
                    </Form.Item> : null}
                </Form>
            </div>
            {(isImgOpen && formData?.uploaded_documents.length > 0) ?
                <Modal
                    centered
                    open={isImgOpen}
                    onCancel={handlePreviewCancel}
                    footer={null}
                    width={"50vw"}>
                    <CommonCarouselPopup documents={[...formData?.uploaded_documents]} />
                </Modal> : null}
        </Modal>
    );
}
const styles = {
    docTxt: {
      color: "#183433",
      fontSize: '0.8vw',
      fontFamily: "Urbanist",
      fontWeight: "500",
      marginBottom: "0.3vw"
    },
    closeIcon: {
        color: "#04B7B1",
        fontSize: "0.95vw",
        fontWeight: "bolder"
    }    
  }
export default EditPayrollAdvanceSalary;