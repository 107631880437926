import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Input, Modal, DatePicker, Select, Tooltip } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import {
  disabledPastDate,
  extractImageName,
} from "components/common/validation";
import { PAYROLL_LEAVE_APPROVAL } from "constants";
import {
  CommonCarouselPopup,
  EmployeeDetails,
} from "components/common/CommonComponent";
import dayjs from "dayjs";
import { EyeOutlined } from "@ant-design/icons";
import IconButton from "components/ui/Button/IconButton";

function AddEditLeaveApproval({
  fromEdit,
  editDetails,
  onHide,
  onSave,
  leaveTypeList,
  isEdit,
}) {
  // console.log(editDetails, "editDetails");

  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const prevPropsRef = useRef();
  const dateFormat = "YYYY-MM-DD";

  const [formData, setFormData] = useState({
    leave_type: undefined,
    from_date: undefined,
    to_date: undefined,
    rejoining_date: undefined,
    requested_date: undefined,
    reason: "",
    approval_notes: "",
    company_id: companyid,
    uploaded_documents: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [leaveInfo, setLeaveInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isImgOpen, setImgOpen] = useState(false);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  useEffect(() => {
    if (
      editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)
    ) {
      let documentList =
        editDetails.uploaded_documents &&
        editDetails.uploaded_documents?.length > 0
          ? editDetails.uploaded_documents.map((url) => ({
              name: extractImageName(url),
              url: url,
            }))
          : [];
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        company: editDetails.company_id,
        leave_id: editDetails.leave_id,
        leave_type: editDetails.leave_type ? editDetails.leave_type : "",
        from_date: editDetails.from_date
          ? dayjs(editDetails.from_date, dateFormat)
          : undefined,
        to_date: editDetails.to_date
          ? dayjs(editDetails.to_date, dateFormat)
          : undefined,
        rejoining_date: editDetails.rejoining_date
          ? dayjs(editDetails.rejoining_date, dateFormat)
          : undefined,
        requested_date: editDetails.requested_date
          ? dayjs(editDetails.requested_date, dateFormat)
          : undefined,
        reason: editDetails.reason ? editDetails.reason : "",
        approval_notes: editDetails.approval_notes
          ? editDetails.approval_notes
          : "",
        uploaded_documents: documentList,
      }));
      form.setFieldsValue({
        leave_type: editDetails.leave_type ? editDetails.leave_type : "",
        from_date: editDetails.from_date
          ? dayjs(editDetails.from_date, dateFormat)
          : undefined,
        to_date: editDetails.to_date
          ? dayjs(editDetails.to_date, dateFormat)
          : undefined,
        rejoining_date: editDetails.rejoining_date
          ? dayjs(editDetails.rejoining_date, dateFormat)
          : undefined,
        requested_date: editDetails.requested_date
          ? dayjs(editDetails.requested_date, dateFormat)
          : undefined,
        reason: editDetails.reason ? editDetails.reason : "",
        approval_notes: editDetails.approval_notes
          ? editDetails.approval_notes
          : "",
        uploaded_documents: documentList,
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    onHide();
    setFormData({
      leave_type: undefined,
      from_date: undefined,
      to_date: undefined,
      rejoining_date: undefined,
      requested_date: undefined,
      reason: "",
      approval_notes: "",
      uploaded_documents: [],
    });
  };

  const handleSubmit = useCallback(
    (isRejectClked) => {
      const authtoken = sessionStorage.getItem("token");
      try {
        setLoading(true);
        let updatedFormData = { ...formData };
        if (isRejectClked && editDetails?.request_type === "cancel request") {
          updatedFormData = {
            ...updatedFormData,
            request_type: "leave request",
          };
        } else {
          updatedFormData.request_type = editDetails?.request_type
            ? editDetails?.request_type
            : undefined;
        }
        updatedFormData.uploaded_documents =
          updatedFormData?.uploaded_documents?.length > 0
            ? updatedFormData.uploaded_documents.map((image) => image.url)
            : [];
        updatedFormData.from_date = dayjs(updatedFormData.from_date).format(
          dateFormat
        );
        updatedFormData.to_date = dayjs(updatedFormData.to_date).format(
          dateFormat
        );
        updatedFormData.rejoining_date = updatedFormData.rejoining_date
          ? dayjs(updatedFormData.rejoining_date).format(dateFormat)
          : undefined;
        delete updatedFormData.requested_date;
        if (isRejectClked) {
          updatedFormData = {
            ...updatedFormData,
            approval_status:
              editDetails?.request_type === "cancel request"
                ? "approved"
                : "rejected",
          };
        } else {
          updatedFormData = {
            ...updatedFormData,
            approval_status:
              editDetails?.request_type === "cancel request"
                ? "cancelled"
                : "approved",
          };
        }
        fetch(PAYROLL_LEAVE_APPROVAL, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${authtoken}`,
          },
          body: JSON.stringify(updatedFormData),
        })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.status === "success") {
              ToastMsg("success", data.message);
              onSave();
              handleCancel();
              setLoading(false);
              setFormData({
                leave_type: undefined,
                from_date: undefined,
                to_date: undefined,
                rejoining_date: undefined,
                requested_date: undefined,
                reason: "",
                approval_notes: "",
                company_id: companyid,
              });
            } else if (data.status === "fail") {
              setLoading(false);
              ToastMsg("error", data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            ToastMsg("error", error.message);
          });
      } catch (error) {
        ToastMsg("error", error.message);
      }
    },
    [formData, fromEdit, companyid, handleCancel, onSave]
  );

  const handleLeaveInfo = (leaveID) => {
    if (leaveID) {
      // GetLeaveInfoApi(
      //   authtoken,
      //   (data) => {
      //     const lData = data.find(
      //       (option) => option.leave_type.id === leaveID
      //     )
      //     setLeaveInfo(`${"You have only " + lData.available_leaves + " days of " + lData.leave_type.name + "."}`)
      //   },
      //   (error) => {
      //     message.error(error);
      //   }
      // );
    } else {
      setLeaveInfo("");
    }
  };

  const renderDatePicker = (statekey) => {
    return (
      <DatePicker
        allowClear
        inputReadOnly={true}
        value={formData[statekey] ? formData[statekey] : undefined}
        format={"DD-MM-YYYY"}
        style={{ width: "100%" }}
        disabled={fromEdit ? true : false}
        onChange={(dateString) => {
          setFormData({
            ...formData,
            [statekey]: dayjs(dateString).format("YYYY-MM-DD"),
          });
        }}
        placeholder=""
        disabledDate={disabledPastDate}
      />
    );
  };

  const handleSave = useCallback(() => {
    handleSubmit(false);
  }, [handleSubmit]);

  const handleReject = useCallback(() => {
    handleSubmit(true);
  }, [handleSubmit]);

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  let empDetails = {
    ...editDetails.employee,
    designation_id: editDetails?.employee?.designation
      ? {
          id: editDetails?.employee?.designation.id,
          designation_name: editDetails?.employee?.designation.name,
        }
      : { id: 0, designation_name: "" },
    first_name: editDetails?.employee?.employee_name
      ? editDetails?.employee?.employee_name
      : "",
    id: editDetails?.employee?.employee_id,
  };

  const handlePreviewImage = (e) => {
    e.preventDefault();
    if (formData?.uploaded_documents.length > 0) {
      setImgOpen(true);
    }
  };

  const handlePreviewCancel = () => {
    setImgOpen(false);
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={"60vw"}
    >
      <div div className="w-full">
        <p id="common_ApprovalTxt">{"Leave Approval"}</p>
        <Form
          layout="vertical"
          form={form}
          name="basicform"
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          colon={false}
          requiredMark={false}
          style={{ width: "100%" }}
          initialValues={true}
        >
          <div className="flex gap-1">
            <div className="grid grid-cols-1 w-1/2">
              <div className="my-4">
                <p style={styles.docTxt}>{"Employee Details"}</p>
                <EmployeeDetails details={empDetails} />
              </div>
              <Form.Item label="Leave Type " name="leave_type">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  value={formData.leave_type ? formData.leave_type : ""}
                  name={"leave_type"}
                  disabled={fromEdit ? true : false}
                  placeholder="Select Leave Type"
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      leave_type: value ? Number(value) : "",
                    });
                    form.setFieldValue(
                      "leave_type",
                      value ? Number(value) : ""
                    );
                    handleLeaveInfo(Number(value));
                  }}
                  filterOption={filterOption}
                >
                  {leaveTypeList.map((option) =>
                    option.id === formData.leave_type ||
                    option.is_active === true ? (
                      <Select.Option key={option.id} value={option.id}>
                        {option.leave_name}
                      </Select.Option>
                    ) : null
                  )}
                </Select>
                {leaveInfo && (
                  <p style={{ fontSize: "0.75vw", color: "#616161" }}>
                    {leaveInfo}{" "}
                  </p>
                )}
              </Form.Item>
              <div className="grid gap-4 grid-cols-2">
                <Form.Item
                  label="From Date"
                  name="from_date"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  {renderDatePicker("from_date")}
                </Form.Item>
                <Form.Item
                  label="To Date"
                  name="to_date"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  {renderDatePicker("to_date")}
                </Form.Item>
              </div>
              <div className="grid gap-4 grid-cols-2">
                {/* as per discussion with hr rejoining date hidded */}
                {/* <Form.Item label="Rejoining Date" name="rejoining_date">
                {renderDatePicker("rejoining_date")}
              </Form.Item> */}
                <Form.Item label="Requested Date" name="requested_date">
                  {renderDatePicker("requested_date")}
                </Form.Item>
              </div>
              <Form.Item label="Reason" name="reason">
                <Input.TextArea
                  autoComplete="off"
                  bordered="true"
                  className="mt-2 textarea"
                  rows={4}
                  autoSize={{
                    minRows: 3,
                    maxRows: 4,
                  }}
                  maxLength={150}
                  name="reason"
                  disabled={fromEdit ? true : false}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reason: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </div>

            <div className="w-1/2">
              {formData.uploaded_documents?.length > 0 ? (
                <div className="grid grid-cols-2 gap-x-3 gap-y-2 mt-2">
                  {formData.uploaded_documents.map((file, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center border-b"
                      style={{ paddingInline: "0.3dvw" }}
                    >
                      {file?.name?.length > 15 ? (
                        <Tooltip title={file?.name}>
                          {
                            <span style={{ fontSize: "0.8vw" }}>
                              {"..." + file?.name?.slice(-14)}
                            </span>
                          }
                        </Tooltip>
                      ) : (
                        <p style={{ fontSize: "0.8vw" }}>
                          {file?.name ? file?.name : "-"}
                        </p>
                      )}
                      <div>
                        <IconButton
                          title="View"
                          icon={<EyeOutlined style={styles.closeIcon} />}
                          onClick={handlePreviewImage}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              <Form.Item label="Approval Notes" name="approval_notes">
                <Input.TextArea
                  autoComplete="off"
                  bordered="true"
                  className="mt-2 textarea"
                  rows={4}
                  autoSize={{
                    minRows: 3,
                    maxRows: 4,
                  }}
                  maxLength={150}
                  placeholder="   Enter reason for Approval Notes for a leave Approval"
                  name="approval_notes"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      approval_notes: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {isEdit === true &&
          editDetails.approval_status !== "rejected" &&
          editDetails.approval_status !== "cancelled" ? (
            <Form.Item>
              <div className="flex justify-end items-end">
                <MyButton
                  htmlType="button"
                  label={"Reject"}
                  onClick={handleReject}
                  bgColor={"#D94854"}
                  loading={loading}
                  paddingX={"0 1.2vw"}
                  marginRight={"0.625vw"}
                  testID="asgreg_btn_draft"
                />
                <MyButton
                  htmlType="submit"
                  label={"Approve"}
                  loading={loading}
                  paddingX={"0 1.2vw"}
                  bgColor={"#0E862A"}
                />
              </div>
            </Form.Item>
          ) : null}
        </Form>
      </div>
      {isImgOpen && formData?.uploaded_documents.length > 0 ? (
        <Modal
          centered
          open={isImgOpen}
          onCancel={handlePreviewCancel}
          footer={null}
          width={"50vw"}
        >
          <CommonCarouselPopup documents={[...formData?.uploaded_documents]} />
        </Modal>
      ) : null}
    </Modal>
  );
}
const styles = {
  docTxt: {
    color: "#183433",
    fontSize: "0.8vw",
    fontFamily: "Urbanist",
    fontWeight: "500",
    marginBottom: "0.3vw",
  },
  closeIcon: {
    color: "#04B7B1",
    fontSize: "0.95vw",
    fontWeight: "bolder",
  },
};
export default AddEditLeaveApproval;
