import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { DatePicker, Card, Radio, Spin, Select, Checkbox, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import dayjs from "dayjs";
import { getEmployeeShift } from "hooks/api/configurationApi/employeeShiftApi";
import { ImagePaths } from "utils/ImagePath";
import MyButton from "components/ui/Button/MyButton";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import {
  EmployeeDetails,
  ReasonPopup,
} from "components/common/CommonComponent";
import CopyShiftPopup from "./CopyShift";
import { useNavigate, useLocation } from "react-router-dom";
import { getRosterDetailsById } from "hooks/api/rosterApi/rosterHomeApis";
import ToastMsg from "components/common/ToastMsg";
import { INDIVIDUAL_ROSTERING_GET } from "constants";
import { getCSVData } from "components/common/CommonFuntion";
import { WeekDayEnum } from "components/common/enum";
import { CloseOutlined } from "@ant-design/icons";
import { disabledPastDate } from "components/common/validation";
import { EmployeeList } from "components/common/RoasterRenderComponent";

const AddEditRoster = () => {
  const [formData, setFormData] = useState({
    groupBy: "employee",
    shift: undefined,
    isMakeRecurring: false,
    rotational_shifts: [],
    initial_rotational_shifts: [],
    is_published: false,
    sltAllEmployee: false,
    sltWeekOff: [],
    //render week button and shift button
    // from_date: dayjs().startOf('week').add(1, 'day'),
    // to_date: dayjs().endOf('week').add(1, 'day'),
    from_date: null,
    to_date: null,
    isPublishClked: false,
    totalEmployee: 0,
  });
  const [filterDatas, setFilterDatas] = useState({
    department: undefined,
    employee: undefined,
    assignedEmp: false,
    filterVisible: false,
    filteredData: [],
    initialfilteredData: [],
    isApplyClked: false,
  });
  const [dropDownlst, setDropdownLst] = useState({
    shiftList: [],
    departmentList: [],
    employeeList: [],
  });
  const [currentWeek, setCurrentWeek] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [showWeekDates, setShowWeekDates] = useState([]);
  const [tooltipState, setTooltipState] = useState({
    showTooltip: false,
    tooltipPosition: { x: 0, y: 0 },
    omittedValues: [],
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { roster_id, roster_from_date, roster_to_date, editAccess } =
    location.state || {};

  const [dates, setDates] = useState({
    // fromDate: roster_from_date ? dayjs(roster_from_date) : dayjs().startOf('week').add(1, 'day'), // Start from Monday
    // toDate: roster_to_date ? dayjs(roster_to_date) : dayjs().endOf('week').add(1, 'day') // End on Sunday
    fromDate: roster_from_date ? dayjs(roster_from_date) : null,
    toDate: roster_to_date ? dayjs(roster_to_date) : null,
  });
  const [query, setQuery] = useState("");

  const calculateDiffDates = () => {
    return dayjs(dates.toDate).diff(dayjs(dates.fromDate), "day") + 1;
  };
  const [isNextDisabled, setIsNextDisabled] = useState(
    calculateDiffDates() <= 7
  );
  const [isEmployeeSelected, setIsEmployeeSelected] = useState(false);
  const [detailsChanged, setDetailsChanged] = useState({
    is_chg_currentWeek: false,
    is_chg_shift: false,
    is_chg_weekoff: false,
  });
  const refs = useRef({});

  const containerHeight = "70dvh";
  const styles = {
    container: {
      height: containerHeight,
    },
  };

  const transformEmployeeData = (data, sltWeekOffList = []) => {
    return data?.length > 0
      ? data?.map((item) => {
        let isEmpSelected = false;
        const updatedShifts = item?.shift?.map((shiftItem) => {
          if (shiftItem?.shift?.is_assigned) {
            isEmpSelected = true;
          }
          // Check if the day of the shift is in sltWeekOffList and update is_week_manual accordingly
          const shiftDate = dayjs(shiftItem?.date);
          const dayOfWeek = shiftDate?.format("dddd")?.toLowerCase()?.trim();
          const isWeekManual =
            sltWeekOffList?.includes(dayOfWeek) && shiftItem?.is_weekoff
              ? false
              : shiftItem?.is_weekoff
                ? true
                : undefined;
          return {
            ...shiftItem,
            is_week_manual: isWeekManual,
          };
        });
        return {
          ...item,
          employee: {
            ...item.employee,
            is_emp_selected: isEmpSelected ? isEmpSelected : false,
          },
          shift: updatedShifts,
        };
      })
      : [];
  };

  const transformDepartData = (data, sltWeekOffList = []) => {
    return data?.reduce((acc, department) => {
      if (department.employees && department.employees.length > 0) {
        const employees = department?.employees?.map((empData) => {
          // Check if any shift has is_assigned set to true
          let isEmpSelected = false;
          const updatedShifts = empData?.shift?.map((shiftItem) => {
            if (shiftItem?.shift?.is_assigned) {
              isEmpSelected = true;
            }
            // Check if the day of the shift is in sltWeekOffList and update is_week_manual accordingly
            const shiftDate = dayjs(shiftItem?.date);
            const dayOfWeek = shiftDate?.format("dddd")?.toLowerCase()?.trim();
            const isWeekManual =
              sltWeekOffList?.includes(dayOfWeek) && shiftItem?.is_weekoff
                ? false
                : shiftItem?.is_weekoff
                  ? true
                  : undefined;
            return {
              ...shiftItem,
              is_week_manual: isWeekManual,
            };
          });
          return {
            ...empData,
            employee: {
              ...empData.employee,
              is_emp_selected: isEmpSelected || false,
            },
            shift: updatedShifts,
          };
        });

        // Check if any employee in the department is selected
        const isDepartmentSelected = employees?.every(
          (emp) => emp?.employee?.is_emp_selected
        );

        acc.push({
          ...department,
          is_department_selected: isDepartmentSelected
            ? isDepartmentSelected
            : false,
          employees,
        });
      }
      return acc;
    }, []);
  };

  const countTotalEmployees = (rotationalShifts) => {
    const uniqueEmployees = new Set();

    rotationalShifts?.forEach((departmentShift) => {
      departmentShift?.employees?.forEach((employeeShift) => {
        uniqueEmployees?.add(employeeShift?.employee?.id);
      });
    });

    return uniqueEmployees?.size;
  };

  const checkEmployeeSelection = (rotationalShifts) => {
    const employees =
      formData?.groupBy === "employee"
        ? rotationalShifts
        : rotationalShifts?.flatMap((department) => department?.employees);

    return employees?.some((item) => item?.employee?.is_emp_selected);
  };

  const assignRosterDetails = (details) => {
    let { groupBy } = formData;
    if (details && Object.keys(details).length > 0) {
      // const fromDate = details?.from_date ? dayjs(details?.from_date) : dayjs().startOf('week').add(1, 'day');
      // const toDate = details?.to_date ? dayjs(details?.to_date) : dayjs().endOf('week').add(1, 'day');
      const fromDate = details?.from_date ? dayjs(details?.from_date) : null;
      const toDate = details?.to_date ? dayjs(details?.to_date) : null;

      setFormData((prevFormData) => {
        let transformedRotationalShifts = [];
        if (details?.rotational_shifts?.length > 0) {
          if (groupBy === "employee") {
            transformedRotationalShifts = [
              ...transformEmployeeData(
                [...details?.rotational_shifts],
                details?.common_week_off
              ),
            ];
          } else if (groupBy === "department" || groupBy === "team") {
            transformedRotationalShifts = [
              ...transformDepartData(
                [...details?.rotational_shifts],
                details?.common_week_off
              ),
            ];
          }
        }
        // Check if all employees are selected in the transformed data
        const allEmployeesSelected = transformedRotationalShifts?.every((item) =>
          groupBy === "employee"
            ? item.employee.is_emp_selected
            : item.is_department_selected
        );

        return {
          ...prevFormData,
          id: details?.id,
          is_published: details?.is_published,
          from_date: fromDate,
          to_date: toDate,
          rotational_shifts: transformedRotationalShifts,
          initial_rotational_shifts: [...transformedRotationalShifts],
          sltAllEmployee: allEmployeesSelected,
          sltWeekOff: allEmployeesSelected ? details?.common_week_off : [],
          shift: undefined,
          totalEmployee:
            groupBy === "employee"
              ? transformedRotationalShifts?.length
              : countTotalEmployees(transformedRotationalShifts),
        };
      });
      setDates({
        fromDate: fromDate,
        toDate: toDate,
      });
      setCurrentPage(0);
      handleFltClear();
      // setCurrentWeek(fromDate);
    }
  };

  const getRosterDetails = async (queryString) => {
    try {
      setLoading(true);
      const apiData = await getRosterDetailsById(queryString);
      if (apiData && apiData.status === "success" && apiData.data) {
        setLoading(false);
        assignRosterDetails({ ...apiData?.data });
      } else {
        setLoading(false);
        ToastMsg("error", apiData.message);
      }
    } catch (error) {
      setLoading(false);
      ToastMsg("error", error);
    }
  };

  // api calls
  useEffect(() => {
    if (query) {
      getRosterDetails(query);
    }
  }, [query]);

  useEffect(() => {
    let { fromDate, toDate } = dates;
    const top = sessionStorage.getItem("top");
    const profile = sessionStorage.getItem("__profile");
    if(fromDate && toDate && profile){
      let queryStr =
        "roster_id=" +
        (roster_id ? roster_id : "") +
        "&group_by=" +
        (formData?.groupBy ? formData?.groupBy : "employee") +
        "&from_date=" +
        (fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "") +
        "&to_date=" +
        (toDate ? dayjs(toDate).format("YYYY-MM-DD") : "") +
        "&top=" + (top ? top : "") +
        "&__profile=" + (profile ? profile : "");
      setQuery(queryStr);
      setIsNextDisabled(calculateDiffDates() <= 7);
    } else if (fromDate && toDate) {
      let queryStr =
        "roster_id=" +
        (roster_id ? roster_id : "") +
        "&group_by=" +
        (formData?.groupBy ? formData?.groupBy : "employee") +
        "&from_date=" +
        (fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "") +
        "&to_date=" +
        (toDate ? dayjs(toDate).format("YYYY-MM-DD") : "");
      // +
      // "&department=" + (filterDatas?.department ? filterDatas?.department : "") +
      // "&employee=" + (filterDatas?.employee ? filterDatas?.employee : "");
      setQuery(queryStr);
      setIsNextDisabled(calculateDiffDates() <= 7);
    }
  }, [dates.fromDate, dates.toDate, roster_id, formData.groupBy]);

  const calculatePerPage = () => {
    const diffInDays =
      dayjs(dates.toDate).diff(dayjs(dates.fromDate), "day") + 1;
    return Math.min(diffInDays, 7);
  };

  useEffect(() => {
    const showDates = [];
    const diffInDays =
      dayjs(formData?.to_date).diff(dayjs(formData?.from_date), "day") + 1;
    const perPage = calculatePerPage();
    let { from_date } = formData;
    const startDate = dayjs(from_date).startOf("day");
    for (let i = 0; i < Math.min(perPage, diffInDays); i++) {
      const currentDay = startDate.add(i, "day");
      showDates.push(currentDay);
    }
    setShowWeekDates(showDates);
  }, [formData.from_date, formData.to_date, dates.fromDate, dates.toDate]);

  const employeeShift = async () => {
    try {
      const apiData = await getEmployeeShift(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setDropdownLst((prevState) => ({
          ...prevState,
          shiftList: [...apiData.data],
        }));
      } else {
        setDropdownLst((prevState) => ({
          ...prevState,
          shiftList: [],
        }));
      }
    } catch (error) {
      setDropdownLst((prevState) => ({
        ...prevState,
        shiftList: [],
      }));
    }
  };

  const getDepartmentList = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setDropdownLst((prevData) => ({
          ...prevData,
          departmentList: [...apiData.data],
        }));
      } else {
        setDropdownLst((prevData) => ({
          ...prevData,
          departmentList: [],
        }));
      }
    } catch (error) {
      setDropdownLst((prevData) => ({
        ...prevData,
        departmentList: [],
      }));
    }
  };

  const getEmployeeList = async () => {
    try {
      const apiData = await getEmployee(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setDropdownLst((prevData) => ({
          ...prevData,
          employeeList: [...apiData.data],
        }));
      } else {
        setDropdownLst((prevData) => ({
          ...prevData,
          employeeList: [],
        }));
      }
    } catch (error) {
      setDropdownLst((prevData) => ({
        ...prevData,
        employeeList: [],
      }));
    }
  };

  useEffect(() => {
    employeeShift();
    getDepartmentList();
    getEmployeeList();
  }, []);

  // useEffect(()=>{
  //   const employeeSelected = checkEmployeeSelection(formData?.rotational_shifts);

  //   // Update state based on employee selection
  //   setIsEmployeeSelected(employeeSelected);

  //   if (!employeeSelected) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       shift: undefined,
  //       sltWeekOff: []
  //     }))
  //     setCurrentWeek(null);
  //   }
  // },[formData?.rotational_shifts, formData?.groupBy])

  useEffect(() => {
    const dataToCheck =
      filterDatas?.filteredData?.length > 0
        ? filterDatas?.filteredData
        : formData?.rotational_shifts;

    const employeeSelected = checkEmployeeSelection(dataToCheck);

    setIsEmployeeSelected(employeeSelected);

    if (!employeeSelected) {
      setFormData((prevState) => ({
        ...prevState,
        shift: undefined,
        sltWeekOff: [],
      }));
      setCurrentWeek(null);
    }
  }, [
    formData?.rotational_shifts,
    filterDatas?.filteredData,
    formData?.groupBy,
  ]);

  const updateRotationalShifts = (rotationalArr) => {
    if (!rotationalArr || rotationalArr?.length === 0) {
      return [];
    }

    const updateShiftData = (shiftData) => {
      const shiftDetails = dropDownlst?.shiftList?.find(
        (item) => item?.id === formData?.shift
      );
      const shiftId = shiftDetails?.id || shiftData?.shift?.id;
      const shiftName = shiftDetails?.shift_name || shiftData?.shift?.name;
      const shiftDate = dayjs(shiftData?.date);
      const currentWeekDate = dayjs(currentWeek);
      // Check if the day of the week is a week off
      const dayOfWeek = shiftDate?.format("dddd")?.toLowerCase()?.trim();
      const sltWeekOffLower = formData?.sltWeekOff?.map((day) =>
        day?.toLowerCase()?.trim()
      );
      const isWeekOff = !shiftData?.is_week_manual
        ? sltWeekOffLower?.includes(dayOfWeek)
        : shiftData?.is_weekoff;

      if (currentWeek && formData?.shift) {
        const isSameDate = shiftDate?.isSame(currentWeekDate, "day");
        // const isSameWeekDay = shiftDate?.day() === currentWeekDate?.day();
        // if (isSameDate || isSameWeekDay) {
        if (isSameDate) {
          return {
            ...shiftData,
            // is_weekoff: isWeekOff,
            is_weekoff: false,
            shift: { ...shiftData?.shift, id: shiftId, name: shiftName },
          };
        } else {
          return {
            ...shiftData,
            is_weekoff: isWeekOff,
          };
        }
      }

      return {
        ...shiftData,
        is_weekoff: isWeekOff,
        shift: { ...shiftData?.shift, id: shiftId, name: shiftName },
      };
    };

    const updateEmployeeData = (empData) => ({
      ...empData,
      shift: empData?.shift?.map((shiftData) =>
        empData?.employee?.is_emp_selected
          ? updateShiftData(shiftData)
          : shiftData
      ),
    });

    if (formData?.groupBy === "employee") {
      return rotationalArr?.map(updateEmployeeData);
    } else {
      return rotationalArr?.map((departmentData) => ({
        ...departmentData,
        employees: departmentData?.employees?.map(updateEmployeeData),
      }));
    }
  };

  const updateRotationalshiftFromFilterdata = (data, rotationalShift) => {
    if (formData?.groupBy === "employee") {
      data?.forEach((modifiedEmployee) => {
        const index = rotationalShift?.findIndex(
          (shiftEmployee) =>
            shiftEmployee?.employee?.id === modifiedEmployee?.employee?.id
        );
        if (index !== -1) {
          rotationalShift[index] = { ...modifiedEmployee };
        }
      });
    } else {
      data?.forEach((filterDataItem) => {
        const shiftDepartmentOrTeam = rotationalShift?.find(
          (item) =>
            (formData?.groupBy === "department" &&
              item?.department?.id === filterDataItem?.department?.id) ||
            (formData?.groupBy === "team" &&
              item?.team?.id === filterDataItem?.team?.id)
        );

        if (shiftDepartmentOrTeam) {
          filterDataItem?.employees?.forEach((filterEmp) => {
            const shiftEmployeeIndex =
              shiftDepartmentOrTeam?.employees?.findIndex(
                (emp) => emp?.employee?.id === filterEmp?.employee?.id
              );

            if (shiftEmployeeIndex !== -1) {
              shiftDepartmentOrTeam.employees[shiftEmployeeIndex] = {
                ...filterEmp,
              };
            }
          });
        }
      });
    }
    setFormData((prevState) => {
      return {
        ...prevState,
        rotational_shifts:
          rotationalShift?.length > 0
            ? rotationalShift
            : prevState?.rotational_shifts,
      };
    });
  };

  // updating the filter data to the rotational data;
  useEffect(() => {
    updateRotationalshiftFromFilterdata(
      [...filterDatas?.filteredData],
      [...formData.rotational_shifts]
    );
  }, [filterDatas?.filteredData]);

  useEffect(() => {
    if (
      detailsChanged?.is_chg_currentWeek ||
      detailsChanged?.is_chg_shift ||
      detailsChanged?.is_chg_weekoff
    ) {
      if (filterDatas?.filteredData?.length > 0) {
        const updatedShifts = updateRotationalShifts(filterDatas?.filteredData);
        const data =
          updatedShifts?.length > 0 ? updatedShifts : filterDatas?.filteredData;
        setFilterDatas((prevState) => {
          return {
            ...prevState,
            filteredData: [...data],
          };
        });
      } else {
        setFormData((prevState) => {
          const updatedShifts = updateRotationalShifts(
            prevState?.rotational_shifts
          );
          return {
            ...prevState,
            rotational_shifts:
              updatedShifts?.length > 0
                ? updatedShifts
                : prevState?.rotational_shifts,
          };
        });
      }
      setDetailsChanged({
        is_chg_currentWeek: false,
        is_chg_shift: false,
        is_chg_weekoff: false,
      });
    }
  }, [JSON.stringify(detailsChanged)]);

  const convertStructure = (rotationalArr) => {
    if (!rotationalArr?.length) {
      return [];
    }

    const processEmployee = (empData) => {
      const updatedShift = empData?.shift?.map((shiftData) => ({
        date: shiftData?.date,
        shift: shiftData?.shift?.id || "",
        is_leave: shiftData?.is_leave || false,
        is_weekoff: shiftData?.is_weekoff || false,
      }));
      return { employee: empData?.employee?.id, shift: updatedShift };
    };

    const filterAndProcessEmployees = (arr) => {
      return arr.reduce((result, item) => {
        if (item?.employee?.is_emp_selected) {
          result.push(processEmployee(item));
        }
        return result;
      }, []);
    };

    if (formData.groupBy === "employee") {
      return filterAndProcessEmployees(rotationalArr);
    } else {
      return rotationalArr?.flatMap((departmentData) =>
        filterAndProcessEmployees(departmentData?.employees)
      );
    }
  };

  const handleDayClick = (date) => {
    const dayOfWeek = date?.format("dddd")?.toLowerCase();
    // Check if the clicked date is a week off day
    const isWeekOff = formData?.sltWeekOff?.includes(dayOfWeek);
    if (isWeekOff) {
      // If it's a week off day, set the message and do nothing
      ToastMsg("warning", "Cannot select a week-off day.");
      return;
    }
    let newDate = currentWeek && date.isSame(currentWeek, "day") ? null : date;

    setCurrentWeek(newDate);
    setDetailsChanged((prevState) => ({
      ...prevState,
      is_chg_currentWeek: true,
    }));
  };

  const handleNextWeek = () => {
    const nextFromDate = dayjs(formData.from_date).add(7, "day");
    if (nextFromDate?.isAfter(formData?.to_date)) {
      setIsNextDisabled(true);
      return;
    }
    setCurrentPage((prevPage) => prevPage + 1);
    setFormData((prevState) => ({
      ...prevState,
      from_date: prevState?.from_date?.add(7, "day"),
      to_date: prevState?.to_date,
    }));

    // Check if the next button should be disabled after updating the formData
    const nextNextFromDate = nextFromDate?.add(7, "day");
    setIsNextDisabled(nextNextFromDate?.isAfter(formData.to_date));
  };

  // Function to handle previous week button click
  const handlePrevWeek = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
      setFormData((prevState) => ({
        ...prevState,
        from_date: prevState?.from_date?.subtract(7, "day"),
        to_date: prevState?.to_date,
      }));
      setIsNextDisabled(false);
    }
  };

  // week button function for the date picker based on from and to date
  const renderWeekButtons = () => {
    return (
      <div className="flex" style={{ width: "82%" }}>
        {showWeekDates?.map((date) => {
          let isDateSame = date.isSame(currentWeek, "day");
          return (
            <div
              className={`border-r rostering_date_btn ${editAccess && isEmployeeSelected
                  ? "cursor-pointer"
                  : "cursor-default"
                } flex flex-col items-center justify-center`}
              key={date.format("YYYY-MM-DD")}
              onClick={() =>
                editAccess && isEmployeeSelected ? handleDayClick(date) : {}
              }
            >
              <p className="musterStatusTxt" style={{ fontWeight: 600 }}>
                {date.format("dddd")}
              </p>
              <p
                className="rostering_date_Txt"
                style={{
                  padding: "0dvw 0.625dvw",
                  background: isDateSame ? "#04B7B1" : "transparent",
                  color: isDateSame ? "white" : "#616161",
                }}
              >
                {date.format("DD/MM/YYYY")}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const updateShiftWithDate = (shiftItem, date) => {
    if (
      shiftItem.date === date ||
      dayjs(shiftItem?.date).day() === dayjs(date).day()
    ) {
      return {
        ...shiftItem,
        is_weekoff: !shiftItem?.is_weekoff,
        is_week_manual:
          shiftItem?.is_week_manual === false
            ? undefined
            : !shiftItem?.is_week_manual,
      };
    }
    return shiftItem;
  };

  const handleShiftClick = (employeeId, date, id = "") => {
    const updateFilterRotationalData = (data, id) => {
      return data?.map((item) => {
        if (formData.groupBy !== "employee" && id) {
          let itemId =
            formData?.groupBy === "department"
              ? item?.department?.id
              : item?.team?.id;
          if (itemId === id) {
            const updatedEmployees = item?.employees?.map((empData) => {
              if (empData?.employee?.id === employeeId) {
                const updatedShifts = empData?.shift?.map((shiftItem) =>
                  updateShiftWithDate(shiftItem, date)
                );
                const isEmpSelected = updatedShifts?.some(
                  (shift) => !shift?.is_weekoff
                );
                return {
                  ...empData,
                  employee: {
                    ...empData?.employee,
                    is_emp_selected:
                      isEmpSelected || empData?.employee?.is_emp_selected,
                  },
                  is_emp_selected: isEmpSelected || empData?.is_emp_selected,
                  shift: updatedShifts,
                };
              }
              return empData;
            });
            return {
              ...item,
              employees: updatedEmployees,
            };
          }
        } else {
          if (item?.employee?.id === employeeId) {
            const updatedShifts = item?.shift?.map((shiftItem) =>
              updateShiftWithDate(shiftItem, date)
            );
            const isEmpSelected = updatedShifts?.some(
              (shift) => !shift?.is_weekoff
            ); // Check if any shift is not a week off
            return {
              ...item,
              employee: {
                ...item?.employee,
                is_emp_selected:
                  isEmpSelected || item?.employee?.is_emp_selected,
              },
              shift: updatedShifts,
            };
          }
        }
        return item;
      });
    };

    if (filterDatas?.filteredData?.length > 0) {
      setFilterDatas((prevFltData) => ({
        ...prevFltData,
        filteredData: updateFilterRotationalData(prevFltData?.filteredData, id),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        rotational_shifts: updateFilterRotationalData(
          prevFormData?.rotational_shifts,
          id
        ),
      }));
    }
  };

  // shift buttons for employee
  const renderShiftButtons = useCallback(
    (records, id) => {
      if (Object?.keys(records)?.length > 0) {
        let { employee, shift } = records;
        return (
          <div className="flex" style={{ width: "82%" }}>
            {showWeekDates?.map((date, index) => {
              const shiftdetails = shift?.find((shiftData) =>
                dayjs(shiftData?.date)?.isSame(date, "day")
              );

              let shiftText = shiftdetails?.is_weekoff
                ? "Week-Off"
                : shiftdetails?.is_leave
                  ? "Full Day Leave"
                  : shiftdetails?.shift?.name
                    ? shiftdetails?.shift?.name
                    : "";
              return (
                <div
                  className={`rostering_shift_btn ${editAccess ? "cursor-pointer" : "cursor-default"
                    }`}
                  style={{
                    background: shiftdetails?.is_weekoff
                      ? "#D94853"
                      : shiftdetails?.is_leave
                        ? "#EF7A55"
                        : "transparent",
                    border: `2px solid ${shiftdetails?.is_weekoff
                        ? "#D94853"
                        : shiftdetails?.is_leave
                          ? "#EF7A55"
                          : "#04B7B1"
                      }`,
                    width: showWeekDates?.length < 7 ? "9.79dvw" : "10dvw",
                  }}
                  key={index}
                  onClick={() =>
                    editAccess
                      ? handleShiftClick(employee?.id, shiftdetails?.date, id)
                      : {}
                  }
                >
                  {shiftText?.length > 15 ? (
                    <Tooltip title={shiftText}>
                      <span
                        className="musterStatusTxt"
                        style={{
                          fontWeight: 600,
                          color:
                            shiftdetails?.is_weekoff || shiftdetails?.is_leave
                              ? "white"
                              : "#183433",
                        }}
                      >
                        {shiftText?.slice(0, 15) + "..."}
                      </span>
                    </Tooltip>
                  ) : (
                    <p
                      className="musterStatusTxt"
                      style={{
                        fontWeight: 600,
                        color:
                          shiftdetails?.is_weekoff || shiftdetails?.is_leave
                            ? "white"
                            : "#183433",
                      }}
                    >
                      {shiftText}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        );
      }
      return null;
    },
    [showWeekDates, editAccess]
  );

  const handleChange = (statekey, value, fromFlt = false) => {
    if (fromFlt) {
      setFilterDatas((prevState) => ({
        ...prevState,
        [statekey]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [statekey]: value,
      }));
    }

    if (
      (statekey === "sltWeekOff" || statekey === "shift") &&
      fromFlt === false
    ) {
      const key = statekey === "sltWeekOff" ? "is_chg_weekoff" : "is_chg_shift";
      setDetailsChanged((prevState) => ({
        ...prevState,
        [key]: true,
      }));
    }
  };

  const handleDateChange = (statekey, value) => {
    setDates((prevState) => {
      let newState = { ...prevState, [statekey]: value };
      if (
        statekey === "fromDate" &&
        newState.toDate &&
        dayjs(newState.toDate).isBefore(value, "day")
      ) {
        newState.toDate = null;
      }
      return newState;
    });
  };

  const renderSelect = (
    label,
    statekey,
    list = [],
    showKey = "text",
    valueKey = "value",
    required = false,
    fromFlt = false
  ) => {
    if (!refs?.current[statekey]) {
      refs.current[statekey] = React.createRef();
    }
    return (
      <div>
        <div className="commonInputTlt">
          {label} {required ? <span className="requiredTxt">*</span> : null}
        </div>
        <Select
          showSearch
          style={{ width: "100%" }}
          value={
            fromFlt ? filterDatas[statekey] || "" : formData[statekey] || ""
          }
          name={statekey}
          onChange={(value) => handleChange(statekey, value, fromFlt)}
          filterOption={(input, option) =>
            option.children
              ? option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : false
          }
          allowClear
          disabled={statekey === "shift" ? !isEmployeeSelected : false}
          ref={refs.current[statekey]}
          onSelect={() => {
            refs?.current[statekey]?.current?.blur();
          }}
        >
          {list?.length > 0 &&
            list?.map((option) =>
              (valueKey === "id" &&
                (option?.is_active === true ||
                  option?.id === formData[statekey])) ||
                valueKey !== "id" ? (
                <Select.Option key={option[valueKey]} value={option[valueKey]}>
                  {" "}
                  {option[showKey]}
                </Select.Option>
              ) : null
            )}
        </Select>
      </div>
    );
  };

  const copyShiftCls = () => {
    setFormData((prevState) => ({
      ...prevState,
      isMakeRecurring: false,
    }));
  };

  const handleFltClose = () => {
    setFilterDatas((prevState) => ({
      ...prevState,
      filterVisible: false,
    }));
  };

  const filterData = (dataArray, departmentId, employeeId) => {
    if (formData?.groupBy === "employee") {
      return dataArray?.filter((item) => {
        const departmentMatch =
          !departmentId || item?.employee?.department?.id === departmentId;
        const employeeMatch = !employeeId || item?.employee?.id === employeeId;

        return departmentMatch && employeeMatch;
      });
    } else if (formData?.groupBy === "department") {
      return dataArray
        ?.filter(
          (department) =>
            !departmentId || department?.department?.id === departmentId
        )
        ?.map((department) => ({
          ...department,
          employees: department?.employees?.filter(
            (employee) => !employeeId || employee?.employee?.id === employeeId
          ),
        }));
    } else {
      return dataArray?.reduce((acc, team) => {
        const filteredEmployees = team?.employees?.filter((item) => {
          const emp = item?.employee;
          const departmentMatch =
            !departmentId || emp?.department?.id === departmentId;
          const employeeMatch = !employeeId || emp?.id === employeeId;
          return departmentMatch && employeeMatch;
        });

        if (filteredEmployees?.length > 0) {
          acc.push({
            ...team,
            employees: filteredEmployees,
          });
        }
        return acc;
      }, []);
    }
  };

  const handleFltClear = () => {
    setFilterDatas((prevState) => ({
      ...prevState,
      department: undefined,
      employee: undefined,
      assignedEmp: false,
      filteredData: [],
      initialfilteredData: [],
      isApplyClked: false,
    }));
    // let { fromDate, toDate } = dates;
    // if (fromDate && toDate) {
    //   let queryStr = "roster_id=" + (roster_id ? roster_id : "") +
    //     "&group_by=" + (formData?.groupBy ? formData?.groupBy : "employee") +
    //     "&from_date=" + (fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : "") +
    //     "&to_date=" + (toDate ? dayjs(toDate).format('YYYY-MM-DD') : "") +
    //     "&department=" + ("") +
    //     "&employee=" + ("");
    //   setQuery(queryStr);
    // }
  };

  const handleFltApply = () => {
    // let { fromDate, toDate } = dates;
    // if (fromDate && toDate) {
    //   let queryStr = "roster_id=" + (roster_id ? roster_id : "") +
    //     "&group_by=" + (formData?.groupBy ? formData?.groupBy : "employee") +
    //     "&from_date=" + (fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : "") +
    //     "&to_date=" + (toDate ? dayjs(toDate).format('YYYY-MM-DD') : "") +
    //     "&department=" + (filterDatas?.department ? filterDatas?.department : "") +
    //     "&employee=" + (filterDatas?.employee ? filterDatas?.employee : "");
    //   setQuery(queryStr);
    // }
    const filteredEmployees = filterData(
      [...formData?.rotational_shifts],
      filterDatas?.department,
      filterDatas?.employee
    );
    setFilterDatas((prevState) => ({
      ...prevState,
      filteredData: filteredEmployees?.length > 0 ? filteredEmployees : [],
      initialfilteredData:
        filteredEmployees?.length > 0 ? filteredEmployees : [],
    }));
  };

  //checkobox state handling
  const handleSelectAllChange = (checked) => {
    const updateEmployeeState = (employee) => ({
      ...employee,
      is_emp_selected: checked,
    });

    const updateEmployeeShift = (shift, checked) => {
      // if (!currentWeek || !formData?.shift) {
      //   return shift;
      // }
      const { shiftList } = dropDownlst;
      const shiftDetails = checked
        ? shiftList?.find((item) => item?.id === formData?.shift)
        : null;
      return shift?.map((shiftData) => {
        const shiftDate = dayjs(shiftData?.date);
        const currentWeekDate = dayjs(currentWeek);
        // Check if the day of the week is a week off
        const dayOfWeek = shiftDate?.format("dddd")?.toLowerCase()?.trim(); // e.g., 'monday'
        const sltWeekOffLower = formData?.sltWeekOff?.map((day) =>
          day?.toLowerCase()?.trim()
        );
        const isWeekOff = !shiftData?.is_week_manual
          ? sltWeekOffLower?.includes(dayOfWeek)
          : shiftData?.is_weekoff;
        const shiftId = shiftDetails?.id || shiftData?.shift?.id;
        const shiftName = shiftDetails?.shift_name || shiftData?.shift?.name;
        if (checked) {
          const isSameDate = shiftDate?.isSame(currentWeekDate, "day");
          // const isSameWeekDay = shiftDate?.day() === currentWeekDate?.day();
          if (currentWeek && formData?.shift) {
            // if (isSameDate || isSameWeekDay) {
            if (isSameDate) {
              // Update the shift details for the current week dates
              return {
                ...shiftData,
                // is_weekoff: isWeekOff,
                is_weekoff: false,
                shift: { ...shiftData?.shift, id: shiftId, name: shiftName },
              };
            } else {
              return {
                ...shiftData,
                is_weekoff: isWeekOff,
                shift: shiftData?.shift,
              };
            }
          }
          // If currentWeek is not present, update is_weekoff and shift
          return {
            ...shiftData,
            is_weekoff: isWeekOff,
            shift: { ...shiftData?.shift, id: shiftId, name: shiftName },
          };
        } else {
          return shiftData;
        }
      });
    };

    const updateFilterRotationalData = (data) => {
      return data?.map((item) =>
        formData?.groupBy === "employee"
          ? {
            ...item,
            employee: updateEmployeeState(item?.employee),
            shift: updateEmployeeShift(item?.shift, checked),
          }
          : {
            ...item,
            employees: item?.employees?.map((emp) => ({
              ...emp,
              employee: updateEmployeeState(emp?.employee),
              shift: updateEmployeeShift(emp?.shift, checked),
            })),
            is_department_selected:
              checked &&
              item?.employees?.every(
                (emp) => updateEmployeeState(emp.employee)?.is_emp_selected
              ),
          }
      );
    };

    if (filterDatas?.filteredData?.length > 0) {
      setFilterDatas((prevFltData) => {
        let { filteredData, initialfilteredData } = prevFltData;
        let listToMapped = checked
          ? [...filteredData]
          : [...initialfilteredData];
        return {
          ...prevFltData,
          filteredData: updateFilterRotationalData(listToMapped),
        };
      });
      setFormData((prevState) => ({
        ...prevState,
        sltAllEmployee: checked,
      }));
    } else {
      setFormData((prevFormData) => {
        let { rotational_shifts, initial_rotational_shifts } = prevFormData;
        // To update the state based on the changes done
        let listToMapped = checked
          ? [...rotational_shifts]
          : [...initial_rotational_shifts];
        return {
          ...prevFormData,
          sltAllEmployee: checked,
          rotational_shifts: updateFilterRotationalData(listToMapped),
        };
      });
    }
  };

  const updateEmployeeState = (employee, employeeId, checked) => ({
    ...employee,
    is_emp_selected:
      employee?.id === employeeId ? checked : employee?.is_emp_selected,
  });

  const updateEmployeeShift = (shift, employee, employeeId) => {
    // if (!currentWeek || !formData?.shift) {
    //   return shift;
    // }

    const { shiftList } = dropDownlst;
    const shiftDetails = shiftList.find((item) => item?.id === formData?.shift);

    return shift?.map((shiftData) => {
      const shiftDate = dayjs(shiftData?.date);
      const currentWeekDate = dayjs(currentWeek);
      // Check if the day of the week is a week off
      const dayOfWeek = shiftDate?.format("dddd")?.toLowerCase()?.trim(); // e.g., 'monday'
      const sltWeekOffLower = formData?.sltWeekOff?.map((day) =>
        day?.toLowerCase()?.trim()
      );
      const isWeekOff = !shiftData?.is_week_manual
        ? sltWeekOffLower?.includes(dayOfWeek)
        : shiftData?.is_weekoff;
      const shiftId = shiftDetails?.id || shiftData?.shift?.id;
      const shiftName = shiftDetails?.shift_name || shiftData?.shift?.name;
      if (employee?.id === employeeId) {
        const isSameDate = shiftDate?.isSame(currentWeekDate, "day");
        // const isSameWeekDay = shiftDate?.day() === currentWeekDate?.day();
        if (currentWeek && formData?.shift) {
          // if (isSameDate || isSameWeekDay) {
          if (isSameDate) {
            return {
              ...shiftData,
              // is_weekoff: isWeekOff,
              is_weekoff: false,
              shift: { ...shiftData?.shift, id: shiftId, name: shiftName },
            };
          } else {
            return {
              ...shiftData,
              is_weekoff: isWeekOff,
              shift: shiftData?.shift,
            };
          }
        } else {
          // If currentWeek is not present, update is_weekoff and shift
          return {
            ...shiftData,
            is_weekoff: isWeekOff,
            shift: { ...shiftData?.shift, id: shiftId, name: shiftName },
          };
        }
      } else {
        return shiftData;
      }
    });
  };

  const handleEmpSltChange = (employeeId, checked) => {
    const updateFltRotationalData = (data, initialData) => {
      return data?.map((item, index) => {
        if (item?.employee?.id === employeeId && !checked) {
          // If unchecked, revert to initial/default shifts
          const initialEmployee = initialData[index];
          return {
            ...item,
            employee: updateEmployeeState(
              initialEmployee?.employee,
              employeeId,
              checked
            ),
            shift: initialEmployee?.shift, // Revert to initial shifts
          };
        }
        return {
          ...item,
          employee: updateEmployeeState(item?.employee, employeeId, checked),
          shift: updateEmployeeShift(item?.shift, item?.employee, employeeId),
        };
      });
    };

    if (filterDatas?.filteredData?.length > 0) {
      let { filteredData, initialfilteredData } = filterDatas;
      const updatedFilterShift = updateFltRotationalData(
        [...filteredData],
        [...initialfilteredData]
      );
      setFilterDatas((prevFltData) => {
        return {
          ...prevFltData,
          filteredData: updateFltRotationalData(
            [...filteredData],
            [...initialfilteredData]
          ),
        };
      });
      const allSelected = updatedFilterShift?.every(
        (item) => item?.employee?.is_emp_selected
      );
      setFormData((prevState) => ({
        ...prevState,
        sltAllEmployee: allSelected,
      }));
    } else {
      setFormData((prevFormData) => {
        const updatedRotationalShifts = updateFltRotationalData(
          [...prevFormData?.rotational_shifts],
          [...prevFormData?.initial_rotational_shifts]
        );
        const allSelected = updatedRotationalShifts?.every(
          (item) => item?.employee?.is_emp_selected
        );

        return {
          ...prevFormData,
          rotational_shifts: updatedRotationalShifts,
          sltAllEmployee: allSelected,
        };
      });
    }
  };

  // individual department select all;
  const handleDepartmentSelectChange = (departmentId, checked) => {
    const updateFltRotationalData = (data, initialData) => {
      return data?.map((department, index) => {
        const isSelectedDepartment =
          (formData?.groupBy === "team"
            ? department?.team?.id
            : department?.department?.id) === departmentId;
        if (!checked && isSelectedDepartment) {
          const departDetails = initialData[index];
          return {
            ...departDetails,
            is_department_selected: false,
          };
        }

        return {
          ...department,
          is_department_selected: isSelectedDepartment
            ? checked
            : department?.is_department_selected,
          employees: department?.employees?.map((emp) => {
            const isEmpSelected = isSelectedDepartment
              ? checked
              : emp?.employee?.is_emp_selected;
            return {
              ...emp,
              employee: updateEmployeeState(
                emp?.employee,
                emp?.employee?.id,
                isEmpSelected
              ),
              shift:
                isEmpSelected !== emp?.employee?.is_emp_selected
                  ? updateEmployeeShift(
                    emp?.shift,
                    emp?.employee,
                    emp?.employee?.id
                  )
                  : emp?.shift,
            };
          }),
        };
      });
    };
    if (filterDatas?.filteredData?.length > 0) {
      let { filteredData, initialfilteredData } = filterDatas;
      const updatedFilterShift = updateFltRotationalData(
        [...filteredData],
        [...initialfilteredData]
      );
      setFilterDatas((prevFltData) => {
        return {
          ...prevFltData,
          filteredData: updateFltRotationalData(
            [...filteredData],
            [...initialfilteredData]
          ),
        };
      });
      const allDepartmentsSelected = updatedFilterShift?.every(
        (department) => department?.is_department_selected
      );
      setFormData((prevState) => ({
        ...prevState,
        sltAllEmployee: allDepartmentsSelected,
      }));
    } else {
      setFormData((prevFormData) => {
        const updatedShifts = updateFltRotationalData(
          prevFormData?.rotational_shifts,
          prevFormData?.initial_rotational_shifts
        );
        const allDepartmentsSelected = updatedShifts?.every(
          (department) => department?.is_department_selected
        );

        return {
          ...prevFormData,
          rotational_shifts: updatedShifts,
          sltAllEmployee: allDepartmentsSelected,
        };
      });
    }
  };

  // individual select of data;
  const handleDepartSltChange = (employeeId, checked) => {
    const updateFltRotationalData = (data, initialData) => {
      return data?.map((department, deptIndex) => {
        const updatedEmployees = department?.employees?.map((emp, index) => {
          if (emp?.employee?.id === employeeId && !checked) {
            // If unchecked, revert to initial/default shifts
            const initialEmployee = initialData[deptIndex]?.employees[index];
            return {
              ...emp,
              employee: updateEmployeeState(
                initialEmployee?.employee,
                employeeId,
                checked
              ),
              shift: initialEmployee?.shift, // Revert to initial shifts
            };
          }
          return {
            ...emp,
            employee: updateEmployeeState(emp?.employee, employeeId, checked),
            shift: updateEmployeeShift(emp?.shift, emp?.employee, employeeId),
          };
        });

        const isDepartmentSelected = updatedEmployees?.every(
          (emp) => emp?.employee?.is_emp_selected
        );

        return {
          ...department,
          employees: updatedEmployees,
          is_department_selected: isDepartmentSelected,
        };
      });
    };
    if (filterDatas?.filteredData?.length > 0) {
      let { filteredData, initialfilteredData } = filterDatas;
      const updatedFilterShift = updateFltRotationalData(
        [...filteredData],
        [...initialfilteredData]
      );
      setFilterDatas((prevFltData) => {
        return {
          ...prevFltData,
          filteredData: updateFltRotationalData(
            [...filteredData],
            [...initialfilteredData]
          ),
        };
      });
      const allDepartmentsSelected = updatedFilterShift?.every(
        (department) => department?.is_department_selected
      );
      setFormData((prevState) => ({
        ...prevState,
        sltAllEmployee: allDepartmentsSelected,
      }));
    } else {
      setFormData((prevFormData) => {
        const updatedShifts = updateFltRotationalData(
          prevFormData?.rotational_shifts,
          prevFormData?.initial_rotational_shifts
        );
        const allDepartmentsSelected = updatedShifts?.every(
          (department) => department?.is_department_selected
        );

        return {
          ...prevFormData,
          rotational_shifts: updatedShifts,
          sltAllEmployee: allDepartmentsSelected,
        };
      });
    }
  };

  // final submit
  const handleSubmitClk = (isFromPublished = false) => {
    if (formData.is_published) {
      setFormData((prevState) => ({
        ...prevState,
        isPublishClked: true,
      }));
    } else {
      handleSubmit(isFromPublished);
    }
  };

  const reasonPopupClose = () => {
    setFormData((prevState) => ({
      ...prevState,
      isPublishClked: false,
    }));
  };

  const addCancelComments = (comments) => {
    handleSubmit(true, comments);
  };

  const handleSubmit = useCallback(
    (isFromPublished = false, reason = "") => {
      let { fromDate, toDate } = dates;
      const authtoken = sessionStorage.getItem("token");
      let updatedFormData = {
        roster_id: formData?.id ? formData?.id : undefined,
        from_date: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
        to_date: toDate ? dayjs(toDate).format("YYYY-MM-DD") : "",
        is_published: isFromPublished,
        rotational_shifts: convertStructure(formData?.rotational_shifts),
        remarks: reason ? reason : undefined,
        common_week_off: formData?.sltAllEmployee ? formData?.sltWeekOff : [],
      };

      // Validation check for empty rotational_shifts
      if (
        !updatedFormData?.rotational_shifts ||
        updatedFormData?.rotational_shifts?.length === 0
      ) {
        ToastMsg("error", "Kindly select employees to update shifts.");
        return;
      }
      // if(formData?.sltWeekOff?.length === 0){
      //   ToastMsg("warning", "The common week off for employees is not selected.");
      // }

      try {
        setLoading(true);
        fetch(INDIVIDUAL_ROSTERING_GET, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${authtoken}`,
          },
          body: JSON.stringify(updatedFormData),
        })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.status === "success") {
              setLoading(false);
              navigate("/roster");
              setFormData({
                groupBy: "employee",
                shift: undefined,
                assignedEmp: false,
                isMakeRecurring: false,
                rotational_shifts: [],
                initial_rotational_shifts: [],
                is_published: false,
                sltAllEmployee: false,
                // from_date: dayjs().startOf('week').add(1, 'day'),
                // to_date: dayjs().endOf('week').add(1, 'day'),
                from_date: null,
                to_date: null,
                department: undefined,
                employee: undefined,
                sltWeekOff: [],
                isPublishClked: false,
              });
              let from_Date = dayjs().startOf("week").add(1, "day");
              // setCurrentWeek(from_Date); hidding  the default selection the date
              setDates({
                // fromDate: from_Date,
                // toDate: dayjs().endOf('week').add(1, 'day')
                fromDate: null,
                toDate: null,
              });
              // setQuery(`roster_id= ${roster_id ? roster_id : ""}&group_by=employee`);
              ToastMsg("success", data.message);
            } else if (data.status === "fail") {
              ToastMsg("error", data.message);
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            ToastMsg("error", error);
            // error.json().then(data => {
            //   setLoading(false);
            //   ToastMsg("error", data);
            // });
          });
      } catch (error) {
        ToastMsg("error", error.message);
      }
    },
    [formData, dates.fromDate, dates.toDate, roster_id]
  );

  const dateRangeRestriction = (current) => {
    // Disable dates before today
    if (current && current < dayjs().startOf("day")) {
      return true;
    }
    // Disable dates more than 29 days ahead from from_date
    if (current && dayjs(current).diff(dayjs(dates.fromDate), "day") > 29) {
      return true;
    }
    // Disable dates before fromDate
    if (
      dates.fromDate &&
      current &&
      current < dayjs(dates.fromDate).startOf("day")
    ) {
      return true;
    }
    return false;
  };

  const renderDatePicker = (
    label,
    statekey,
    disabledDates = disabledPastDate
  ) => {
    return (
      <>
        <div className="commonInputTlt">{label}</div>
        <DatePicker
          disabled={roster_id || !editAccess ? true : false}
          allowClear
          inputReadOnly={true}
          locale={locale}
          style={{ width: "100%" }}
          name={statekey}
          value={dates[statekey] ? dayjs(dates[statekey]) : null}
          format={"DD-MM-YYYY"}
          onChange={(date) => handleDateChange(statekey, date)}
          disabledDate={disabledDates}
          placeholder=""
        />
      </>
    );
  };

  // omitted list showing
  const handleToggleTooltip = (visible, values, position) => {
    setTooltipState({
      showTooltip: visible,
      omittedValues: values,
      tooltipPosition: position,
    });
  };

  const handleMouseEnter = (event, omittedValues) => {
    const rect = event.target.getBoundingClientRect();
    handleToggleTooltip(true, omittedValues, { x: rect.left, y: rect.bottom });
  };

  const handleMouseLeave = () => {
    handleToggleTooltip(false, [], { x: 0, y: 0 });
  };

  const renderMaxTagPlaceholder = (omittedValues) => (
    <div
      onMouseEnter={(event) => handleMouseEnter(event, omittedValues)}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: "pointer" }}
    >
      +{omittedValues.length}
    </div>
  );

  const getCurrentWeekday = (currentWeek) => {
    return currentWeek ? currentWeek?.format("dddd")?.toLowerCase() : null;
  };

  // Get the current weekday to be disabled
  const currentWeekday = getCurrentWeekday(currentWeek);

  // rendering list based on the filter
  let renderRotationalList = [];
  if (
    (filterDatas?.department || filterDatas?.employee) &&
    filterDatas?.isApplyClked
  ) {
    renderRotationalList = filterDatas?.filteredData;
  } else {
    renderRotationalList = formData?.rotational_shifts;
  }

  // minimal optimization render for week days
  const weekButtons = useMemo(
    () => renderWeekButtons(),
    [showWeekDates, currentWeek, editAccess, isEmployeeSelected]
  );

  // method 1
  // function with optimized rendering
  const renderEmployeeList = useMemo(() => {
    return renderRotationalList?.map((item) => {
      if (formData?.groupBy === "employee") {
        return (
          <div className="mb-1">
            <div className="flex items-center border-b p-1">
              {/* checkbox with employee details */}
              <Checkbox
                disabled={!editAccess}
                className="h-full flex items-center border-r custom-roster-checkbox"
                style={{ width: "14dvw" }}
                checked={item?.employee?.is_emp_selected}
                onChange={(e) =>
                  handleEmpSltChange(item?.employee?.id, e.target.checked)
                }
              >
                <span>
                  <EmployeeDetails
                    details={{
                      ...item?.employee,
                      title: item?.employee?.title ? item?.employee?.title : "",
                      profile: item?.employee?.profile
                        ? item?.employee?.profile
                        : "",
                      designation_id: item?.employee?.designation
                        ? {
                          id: item?.employee?.designation.id,
                          designation_name: item?.employee?.designation.name,
                        }
                        : { id: 0, designation_name: "" },
                      first_name: item?.employee?.employee_name
                        ? item?.employee?.employee_name
                        : "",
                      id: item?.employee?.employee_id
                        ? item?.employee?.employee_id
                        : "",
                    }}
                  />
                </span>
              </Checkbox>

              {/* remaining shift time table of a respective employee*/}
              {renderShiftButtons(item)}
            </div>
          </div>
        );
      } else if (formData?.groupBy === "department") {
        return item?.employees?.length > 0 ? (
          <div className="mb-1">
            <div className="rostering_groupby_txt">
              {/* checkbox with department details */}
              <Checkbox
                disabled={!editAccess}
                className="custom-department-checkbox"
                checked={item?.is_department_selected}
                onChange={(e) =>
                  handleDepartmentSelectChange(
                    item?.department?.id,
                    e.target.checked
                  )
                }
              >
                <span className="musterStatusTxt" style={{ fontWeight: 600 }}>
                  {item?.department?.name ? item?.department?.name : ""}
                </span>
              </Checkbox>
            </div>

            {/* separating the employees of a respective department*/}
            {item?.employees?.length > 0
              ? item?.employees?.map((employeeData) => (
                <div className="flex items-center border-b p-1"
                  key={employeeData?.employee?.id}
                >
                  {/* checkbox with employee details */}
                  <Checkbox
                    disabled={!editAccess}
                    className="h-full flex items-center border-r custom-roster-checkbox"
                    style={{ width: "14dvw" }}
                    checked={employeeData?.employee?.is_emp_selected}
                    onChange={(e) =>
                      handleDepartSltChange(
                        employeeData?.employee?.id,
                        e.target.checked
                      )
                    }
                  >
                    <span>
                      <EmployeeDetails
                        details={{
                          ...employeeData?.employee,
                          title: employeeData?.employee?.title
                            ? employeeData?.employee?.title
                            : "",
                          profile: employeeData?.employee?.profile
                            ? employeeData?.employee?.profile
                            : "",
                          designation_id: employeeData?.employee?.designation
                            ? {
                              id: employeeData?.employee?.designation.id,
                              designation_name:
                                employeeData?.employee?.designation.name,
                            }
                            : { id: 0, designation_name: "" },
                          first_name: employeeData?.employee?.employee_name
                            ? employeeData?.employee?.employee_name
                            : "",
                          id: employeeData?.employee?.employee_id
                            ? employeeData?.employee?.employee_id
                            : "",
                        }}
                      />
                    </span>
                  </Checkbox>

                  {/* remaining shift time table of a respective employee*/}
                  {renderShiftButtons(employeeData, item?.department?.id)}
                </div>
              ))
              : null}
          </div>
        ) : null;
      } else if (formData?.groupBy === "team") {
        return item?.employees?.length > 0 ? (
          <div className="mb-1">
            <div className="password-tlt rostering_groupby_txt">
              {/* checkbox for team selection */}
              <Checkbox
                disabled={!editAccess}
                className="custom-department-checkbox"
                checked={item?.is_department_selected}
                onChange={(e) =>
                  handleDepartmentSelectChange(item?.team?.id, e.target.checked)
                }
              >
                <span className="musterStatusTxt" style={{ fontWeight: 600 }}>
                  {item?.team?.name ? item?.team?.name : ""}
                </span>
              </Checkbox>
            </div>

            {/* separating the employees of a respective team*/}
            {item?.employees?.length > 0
              ? item?.employees?.map((employeeData) => (
                <div
                  className="flex items-center border-b p-1"
                  key={employeeData?.employee?.id}
                >
                  {/* checkbox with employee details */}
                  <Checkbox
                    disabled={!editAccess}
                    className="h-full flex items-center border-r custom-roster-checkbox"
                    style={{ width: "14dvw" }}
                    checked={employeeData?.employee?.is_emp_selected}
                    onChange={(e) =>
                      handleDepartSltChange(
                        employeeData?.employee?.id,
                        e.target.checked
                      )
                    }
                  >
                    <span>
                      <EmployeeDetails
                        details={{
                          ...employeeData?.employee,
                          title: employeeData?.employee?.title
                            ? employeeData?.employee?.title
                            : "",
                          profile: employeeData?.employee?.profile
                            ? employeeData?.employee?.profile
                            : "",
                          designation_id: employeeData?.employee?.designation
                            ? {
                              id: employeeData?.employee?.designation.id,
                              designation_name:
                                employeeData?.employee?.designation.name,
                            }
                            : { id: 0, designation_name: "" },
                          first_name: employeeData?.employee?.employee_name
                            ? employeeData?.employee?.employee_name
                            : "",
                          id: employeeData?.employee?.employee_id
                            ? employeeData?.employee?.employee_id
                            : "",
                        }}
                      />
                    </span>
                  </Checkbox>

                  {/* remaining shift time table of a respective employee*/}
                  {renderShiftButtons(employeeData, item?.team?.id)}
                </div>
              ))
              : null}
          </div>
        ) : null;
      }
    });
  }, [renderRotationalList, formData?.groupBy, handleEmpSltChange, editAccess]);

  return (
    <Card className="w-full h-full">
      {loading && (
        <div className="loaderOverlay">
          <Spin />
        </div>
      )}
      {/* date filters & (employee || department || team) filters & action buttons */}
      <div className="h-1/5">
        <div className="flex justify-between items-center border-b py-1">
          {/* back and title */}
          <div className="flex justify-start items-center">
            <Tooltip title={"Back"} placement="bottom">
              <img
                src={ImagePaths.backArrow.default}
                alt="Back"
                className="rosteringBackIcon cursor-pointer"
                onClick={() => {
                  navigate("/roster");
                }}
              />
            </Tooltip>
            <p
              className="subEmployee_detail_txt ml-1"
              style={{ color: "#183433" }}
            >
              Shift Rostering
            </p>
          </div>

          {/* count & filter */}
          <div className="flex justify-center items-center">
            <p className="subEmployee_detail_txt" style={{ fontSize: "1dvw" }}>
              {`Total Employees : ${formData?.totalEmployee}`}
            </p>
            <Radio.Group
              name="groupBy"
              className="custom-roster-radio-group ml-4"
              onChange={(e) => handleChange("groupBy", e.target.value)}
              value={formData?.groupBy ? formData?.groupBy : ""}
            >
              <Radio.Button value="employee">Employee</Radio.Button>
              <Radio.Button value="department">Department</Radio.Button>
              <Radio.Button value="team">Team</Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <div className="flex justify-between items-center py-2">
          {/* from & to date */}
          <div className="flex justify-start items-center">
            <div className="mr-2">
              {renderDatePicker("Select From Date", "fromDate")}
            </div>
            <div className="mx-2">
              {renderDatePicker(
                "Select To Date",
                "toDate",
                dateRangeRestriction
              )}
            </div>
            {/* enable field for editAccess true */}
            {editAccess ? (
              <>
                <div className="mx-2" style={{ width: "13dvw" }}>
                  {renderSelect(
                    "Select Shift",
                    "shift",
                    dropDownlst.shiftList,
                    "shift_name",
                    "id",
                    true
                  )}
                </div>
                <div className="mx-2" style={{ width: "13dvw" }}>
                  <div className="commonInputTlt">Select WeekOff Day</div>
                  <Select
                    showSearch
                    mode="multiple"
                    showArrow="true"
                    maxTagCount={1}
                    maxTagTextLength={5}
                    style={{ width: "100%" }}
                    value={formData?.sltWeekOff ? formData?.sltWeekOff : ""}
                    name={"sltWeekOff"}
                    disabled={!isEmployeeSelected}
                    onChange={(value) => handleChange("sltWeekOff", value)}
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                    maxTagPlaceholder={(omittedValues) =>
                      renderMaxTagPlaceholder(omittedValues)
                    }
                  >
                    {WeekDayEnum?.map((option) => (
                      <Select.Option
                        key={option?.value}
                        value={option?.value}
                        disabled={
                          showWeekDates?.some((date) =>
                            dayjs(date)?.isSame(currentWeek, "day")
                          ) && option?.value === currentWeekday
                        }
                      >
                        {option?.text}
                      </Select.Option>
                    ))}
                  </Select>
                  {tooltipState?.omittedValues.length > 0 &&
                    tooltipState?.showTooltip && (
                      <div
                        className="commonTooltipOfMutliSlt"
                        style={{
                          position: "fixed",
                          top: tooltipState?.tooltipPosition?.y + 10,
                          left: tooltipState?.tooltipPosition?.x,
                        }}
                      >
                        <ul className="commonTooltipOfMutliSltDiv">
                          {tooltipState?.omittedValues?.map((value) => (
                            <li
                              key={value?.key}
                              className="commonTooltipOfMutliSltTxt"
                            >
                              {value?.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                </div>
              </>
            ) : null}

            {/* <div className='flex items-center password-tlt rostering_recurring_txt cursor-pointer'
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  isMakeRecurring: true
                }))
              }}>
              <img className="mr-2 rosteringIcon" src={ImagePaths.clockReload.default} alt="Make Recurring" />
              Make Recurring</div> */}
          </div>

          {/* filter button & save and publish */}
          <div className="flex justify-start items-center">
            <Tooltip title={"Filter"} placement="bottom">
              <img
                src={ImagePaths.filter.default}
                alt="Filter"
                className="commonTblBackIcon cursor-pointer"
                style={{ marginRight: "0.625dvw" }}
                onClick={() => {
                  setFilterDatas((prevState) => ({
                    ...prevState,
                    filterVisible: true,
                  }));
                }}
              />
            </Tooltip>

            {/* export button to download the table details is removed */}
            {/* <MyButton
              htmlType="button"
              bgColor={roster_id ? "transparent" : "#cacaca"}
              label={"Export"}
              color={"#183433"}
              paddingX={"0 0.625dvw"}
              disabled={!roster_id}
              marginRight={"0.625dvw"}
              className={"flex items-center justify-center"}
              icon={
                <img
                  className="mr-2 rosteringIcon"
                  src={ImagePaths.exportIcon.default}
                  alt="export"
                />
              }
              outlined={!!roster_id}
              onClick={() => {
                getCSVData(INDIVIDUAL_ROSTERING_GET, "?" + query);
              }}
            /> */}

            {/* save and publish buttons */}
            {editAccess ? (
              <>
                {formData?.is_published ? null : (
                  <MyButton
                    htmlType="submit"
                    outlined={isEmployeeSelected ? true : false}
                    label={"Save"}
                    color={"#183433"}
                    paddingX={"0 1.2dvw"}
                    disabled={!isEmployeeSelected}
                    bgColor={isEmployeeSelected ? "transparent" : "#cacaca"}
                    marginRight={"0.625dvw"}
                    onClick={() => handleSubmitClk(false)}
                  />
                )}
                <MyButton
                  htmlType="submit"
                  label={"Publish"}
                  disabled={!isEmployeeSelected}
                  bgColor={isEmployeeSelected ? "#334B49" : "#cacaca"}
                  paddingX={"0 1.2vw"}
                  marginRight={"0.625dvw"}
                  onClick={() => handleSubmitClk(true)}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>

      {/* checkbox container & weeks render & table container */}
      <div className="grid grid-cols-12 h-4/5">
        <div
          className={`${filterDatas.filterVisible ? "col-span-10" : "col-span-12"
            } border`}
          style={styles.container}
        >
          {/* primary header for week days to render */}
          <div
            className="w-full flex items-center justify-between border-b"
            style={{ height: "7dvh" }}
          >
            <div
              style={{ width: "97.5%" }}
              className="h-full flex items-center"
            >
              <div className="h-full flex" style={{ width: "18%" }}>
                {/* to see previous day in a week */}
                <button
                  className="h-full border-r flex items-center"
                  onClick={handlePrevWeek}
                  style={{
                    background: currentPage === 0 ? "#cacaca" : "#04B7B1",
                    cursor: currentPage === 0 ? "default" : "pointer",
                  }}
                  disabled={currentPage === 0}
                >
                  <img
                    src={ImagePaths.previous.default}
                    alt="Previous"
                    className="commonTblBackIcon"
                  />
                </button>

                {/* select all checkbox */}
                <Checkbox
                  disabled={!editAccess}
                  className="h-full border-r flex items-center p-1"
                  checked={formData?.sltAllEmployee}
                  style={{ width: "13dvw" }}
                  onChange={(e) => handleSelectAllChange(e.target.checked)}
                >
                  <span className="rostering_Txt">Employee Details</span>
                </Checkbox>
              </div>

              {/* week button based on from and to date */}
              {weekButtons}
            </div>
            {/* to see ṇext day in a week */}
            <button
              className="h-full flex items-center border-l"
              style={{
                background: isNextDisabled ? "#cacaca" : "#04B7B1",
                cursor: isNextDisabled ? "default" : "pointer",
              }}
              onClick={handleNextWeek}
              disabled={isNextDisabled}
            >
              <img
                src={ImagePaths.next.default}
                alt="next"
                className="commonTblBackIcon"
              />
            </button>
          </div>

          {/* color based info */}
          <div
            className="flex items-center justify-center border-b"
            style={{ height: "6dvh" }}
          >
            <div className="flex items-center" key={"Applied Leave"}>
              <div
                className="dotBtn mx-5"
                style={{ backgroundColor: "#EF7A55" }}
              ></div>
              <span className="rostering_split_txt password-tlt">
                Applied Leave
              </span>
            </div>
            {/* <div className='flex items-center' key = {"Permission"}>
              <div className='dotBtn  mx-5' style={{ backgroundColor: "#F7DD71" }}></div>
              <span className='rostering_split_txt password-tlt'>Permission</span>
            </div> */}
            <div className="flex items-center" key={"Week-off"}>
              <div
                className="dotBtn  mx-5"
                style={{ backgroundColor: "#D94853" }}
              ></div>
              <span className="rostering_split_txt password-tlt">Week-off</span>
            </div>
          </div>

          {/* table datas with shift details */}
          <div style={{
            height: "80%"
            , overflowY: "auto"
          }}>
            {/* Render the employee list */}

            {/* method 1 */}
            {/* {renderRotationalList?.length > 0 ? (
              renderRotationalList?.map((item) => {
                if (formData?.groupBy === "employee") {
                  return (
                    <div className="mb-1">
                      <div className="flex items-center border-b p-1">
                        <Checkbox
                          disabled={!editAccess}
                          className="h-full flex items-center border-r custom-roster-checkbox"
                          style={{ width: "14dvw" }}
                          checked={item?.employee?.is_emp_selected}
                          onChange={(e) =>
                            handleEmpSltChange(
                              item?.employee?.id,
                              e.target.checked
                            )
                          }
                        >
                          <span>
                            <EmployeeDetails
                              details={{
                                ...item?.employee,
                                title: item?.employee?.title
                                  ? item?.employee?.title
                                  : "",
                                profile: item?.employee?.profile
                                  ? item?.employee?.profile
                                  : "",
                                designation_id: item?.employee?.designation
                                  ? {
                                      id: item?.employee?.designation.id,
                                      designation_name:
                                        item?.employee?.designation.name,
                                    }
                                  : { id: 0, designation_name: "" },
                                first_name: item?.employee?.employee_name
                                  ? item?.employee?.employee_name
                                  : "",
                                id: item?.employee?.employee_id
                                  ? item?.employee?.employee_id
                                  : "",
                              }}
                            />
                          </span>
                        </Checkbox>
                        {renderShiftButtons(item)}
                      </div>
                    </div>
                  );
                } else if (formData?.groupBy === "department") {
                  return item?.employees?.length > 0 ? (
                    <div className="mb-1">
                      <div className="rostering_groupby_txt">
                        <Checkbox
                          disabled={!editAccess}
                          className="custom-department-checkbox"
                          checked={item?.is_department_selected}
                          onChange={(e) =>
                            handleDepartmentSelectChange(
                              item?.department?.id,
                              e.target.checked
                            )
                          }
                        >
                          <span
                            className="musterStatusTxt"
                            style={{ fontWeight: 600 }}
                          >
                            {item?.department?.name
                              ? item?.department?.name
                              : ""}
                          </span>
                        </Checkbox>
                      </div>
                      {item?.employees?.length > 0
                        ? item?.employees?.map((employeeData) => (
                            <div
                              className="flex items-center border-b p-1 mb-1"
                              key={employeeData?.employee?.id}
                            >
                              <Checkbox
                                disabled={!editAccess}
                                className="h-full flex items-center border-r custom-roster-checkbox"
                                style={{ width: "14dvw" }}
                                checked={
                                  employeeData?.employee?.is_emp_selected
                                }
                                onChange={(e) =>
                                  handleDepartSltChange(
                                    employeeData?.employee?.id,
                                    e.target.checked
                                  )
                                }
                              >
                                <span>
                                  <EmployeeDetails
                                    details={{
                                      ...employeeData?.employee,
                                      title: employeeData?.employee?.title
                                        ? employeeData?.employee?.title
                                        : "",
                                      profile: employeeData?.employee?.profile
                                        ? employeeData?.employee?.profile
                                        : "",
                                      designation_id: employeeData?.employee
                                        ?.designation
                                        ? {
                                            id: employeeData?.employee
                                              ?.designation.id,
                                            designation_name:
                                              employeeData?.employee
                                                ?.designation.name,
                                          }
                                        : { id: 0, designation_name: "" },
                                      first_name: employeeData?.employee
                                        ?.employee_name
                                        ? employeeData?.employee?.employee_name
                                        : "",
                                      id: employeeData?.employee?.employee_id
                                        ? employeeData?.employee?.employee_id
                                        : "",
                                    }}
                                  />
                                </span>
                              </Checkbox>
                              {renderShiftButtons(
                                employeeData,
                                item?.department?.id
                              )}
                            </div>
                          ))
                        : null}
                    </div>
                  ) : null;
                } else if (formData?.groupBy === "team") {
                  return item?.employees?.length > 0 ? (
                    <div className="mb-1">
                      <div className="password-tlt rostering_groupby_txt">
                        <Checkbox
                          disabled={!editAccess}
                          className="custom-department-checkbox"
                          checked={item?.is_department_selected}
                          onChange={(e) =>
                            handleDepartmentSelectChange(
                              item?.team?.id,
                              e.target.checked
                            )
                          }
                        >
                          <span
                            className="musterStatusTxt"
                            style={{ fontWeight: 600 }}
                          >
                            {item?.team?.name ? item?.team?.name : ""}
                          </span>
                        </Checkbox>
                      </div>
                      {item?.employees?.length > 0
                        ? item?.employees?.map((employeeData) => (
                            <div
                              className="flex items-center border-b p-1 mb-1"
                              key={employeeData?.employee?.id}
                            >
                              <Checkbox
                                disabled={!editAccess}
                                className="h-full flex items-center border-r custom-roster-checkbox"
                                style={{ width: "14dvw" }}
                                checked={
                                  employeeData?.employee?.is_emp_selected
                                }
                                onChange={(e) =>
                                  handleDepartSltChange(
                                    employeeData?.employee?.id,
                                    e.target.checked
                                  )
                                }
                              >
                                <span>
                                  <EmployeeDetails
                                    details={{
                                      ...employeeData?.employee,
                                      title: employeeData?.employee?.title
                                        ? employeeData?.employee?.title
                                        : "",
                                      profile: employeeData?.employee?.profile
                                        ? employeeData?.employee?.profile
                                        : "",
                                      designation_id: employeeData?.employee
                                        ?.designation
                                        ? {
                                            id: employeeData?.employee
                                              ?.designation.id,
                                            designation_name:
                                              employeeData?.employee
                                                ?.designation.name,
                                          }
                                        : { id: 0, designation_name: "" },
                                      first_name: employeeData?.employee
                                        ?.employee_name
                                        ? employeeData?.employee?.employee_name
                                        : "",
                                      id: employeeData?.employee?.employee_id
                                        ? employeeData?.employee?.employee_id
                                        : "",
                                    }}
                                  />
                                </span>
                              </Checkbox>
                              {renderShiftButtons(employeeData, item?.team?.id)}
                            </div>
                          ))
                        : null}
                    </div>
                  ) : null;
                }
              })
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                No Data Found
              </div>
            )} */}

            {/* method 2 */}
            {/* {renderRotationalList?.length > 0 ? (
              // This will insert the memoized employee list here
              renderEmployeeList
            ) : (
              // Render a message when there's no data
              <div className="w-full h-full flex justify-center items-center">
                No Data Found
              </div>
            )} */}

            {/* method 3 */}
            <EmployeeList
              renderShiftButtons={renderShiftButtons}
              renderRotationalList={renderRotationalList}
              formData={formData}
              editAccess={editAccess}
              handleEmpSltChange={handleEmpSltChange}
              handleDepartmentSelectChange={handleDepartmentSelectChange}
              handleDepartSltChange={handleDepartSltChange}
            />
          </div>
        </div>

        {/* filter Starts */}
        {filterDatas.filterVisible ? (
          <div className="col-span-2 border" style={styles.container}>
            {/* filter container header */}
            <div
              className="flex justify-between items-center border-b"
              style={{ height: "7dvh" }}
            >
              <div className="flex items-center password-tlt rostering_Txt">
                <img
                  className="mr-2 commonTblBackIcon"
                  src={ImagePaths.filterFunnel.default}
                  alt="export"
                />
                Filter
              </div>
              <Tooltip title={"Close"} placement="bottom">
                <CloseOutlined
                  className="fltCloseIcon mr-2"
                  onClick={handleFltClose}
                />
              </Tooltip>
            </div>
            {/* filter input */}
            <div style={{ height: "71%", margin: "15px" }}>
              {/* department */}
              <div className="rostering_flt_subDiv">
                {renderSelect(
                  "Select Department",
                  "department",
                  dropDownlst.departmentList,
                  "department_name",
                  "id",
                  false,
                  true
                )}
              </div>
              {/* employee */}
              <div className="rostering_flt_subDiv">
                {renderSelect(
                  "Select Employee",
                  "employee",
                  dropDownlst.employeeList,
                  "first_name",
                  "id",
                  false,
                  true
                )}
              </div>
              {/* <Checkbox
              className='rostering_flt_subDiv'
              checked={formData.assignedEmp}
              onChange={(e) => handleChange("assignedEmp", e.target.checked, true)}
            >
              <span className='password-tlt rostering_recurring_txt'>Show Assigned Employees</span>
            </Checkbox> 
            temporary commit need to go through this*/}
            </div>

            {/* filter footer */}
            <div
              className="flex justify-center items-end"
              style={{ height: "7dvh" }}
            >
              <MyButton
                htmlType="button"
                label="Clear"
                onClick={handleFltClear}
                outlined={true}
                paddingX={"0 0.7dvw"}
                marginRight={"0.625dvw"}
              />
              <MyButton
                htmlType="submit"
                onClick={() => {
                  setFilterDatas((prevState) => ({
                    ...prevState,
                    isApplyClked: true,
                  }));
                  handleFltApply();
                }}
                label={"Filter"}
                paddingX={"0 1.2dvw"}
              />
            </div>
          </div>
        ) : null}
        {/* filter ends */}
      </div>
      {formData?.isMakeRecurring ? (
        <CopyShiftPopup onClose={copyShiftCls} />
      ) : null}
      {formData?.is_published && formData?.isPublishClked ? (
        <ReasonPopup
          title={"Are you sure? Do you want to modify the shift rostering"}
          AlertPopupClose={reasonPopupClose}
          label={"Enter the Reason"}
          sendCmt={addCancelComments}
        />
      ) : null}
    </Card>
  );
};

export default AddEditRoster;
