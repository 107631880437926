import React, { memo, useMemo } from "react";
import { Tooltip } from "antd";
import { bloodGroupEnum, genderEnum, relationshipEnum, titleEnum } from "./enum";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";

const areEqual = (prevProps, nextProps) => {
    return prevProps.item === nextProps.item &&
        prevProps.index === nextProps.index &&
        prevProps.onChange === nextProps.onChange;
};

const Item = memo(
    ({
        item,
        onChange,
        index,
        departmentlist,
        designationList,
        eType,
        eShift,
        userGroup,
        employeeGroupList,
        handleDeleteClicked,
        validateField,
        parseAndFormatDate,
        migratekey,
    }) => {

        // Simple validation for all fields
        const getErrorMessage = (field) => validateField(field, item[field], item);

        const titleErrorMessage = useMemo(
            () => getErrorMessage("Title"),
            [item.Title]
        );
        const firstNameErrorMessage = useMemo(
            () => getErrorMessage("FirstName"),
            [item.FirstName]
        );
        const lastNameErrorMessage = useMemo(
            () => getErrorMessage("LastName"),
            [item.LastName]
        );
        const personalEmailErrorMessage = useMemo(
            () => getErrorMessage("PersonalEmailID"),
            [item.PersonalEmailID]
        );
        const ProfessionalEmailErrorMessage = useMemo(
            () => getErrorMessage("ProfessionalEmailID"),
            [item.ProfessionalEmailID]
        );
        const personalMobileNoErrorMessage = useMemo(
            () => getErrorMessage("PersonalMobileNo"),
            [item.PersonalMobileNo]
        );
        const departmentErrorMessage = useMemo(
            () => getErrorMessage("Department"),
            [item.Department]
        );
        const designationErrorMessage = useMemo(
            () => getErrorMessage("Designation"),
            [item.Designation]
        );
        const employeeTypeErrorMessage = useMemo(
            () => getErrorMessage("EmployeeType"),
            [item.EmployeeType]
        );
        const dojErrorMessage = useMemo(
            () => getErrorMessage("DateOfJoining"),
            [item.DateOfJoining]
        );
        const shiftErrorMessage = useMemo(
            () => getErrorMessage("Shift"),
            [item.Shift]
        );
        const cityErrorMessage = useMemo(
            () => getErrorMessage("City"),
            [item.City]
        );
        const stateErrorMessage = useMemo(
            () => getErrorMessage("State"),
            [item.State]
        );
        const pincodeErrorMessage = useMemo(
            () => getErrorMessage("Pincode"),
            [item.Pincode]
        );
        const presentAddressCityErrorMessage = useMemo(
            () => getErrorMessage("PresentAddressCity"),
            [item.PresentAddressCity]
        );
        const presentAddressStateErrorMessage = useMemo(
            () => getErrorMessage("PresentAddressState"),
            [item.PresentAddressState]
        );
        const presentAddressPincodeErrorMessage = useMemo(
            () => getErrorMessage("PresentAddressPincode"),
            [item.PresentAddressPincode]
        );
        const userGroupErrorMessage = useMemo(
            () => getErrorMessage("UserGroup"),
            [item.UserGroup]
        );
        const usernameErrorMessage = useMemo(
            () => getErrorMessage("Username"),
            [item.Username]
        );
        const passwordErrorMessage = useMemo(
            () => getErrorMessage("Password"),
            [item.Password]
        );
        const genderErrorMessage = useMemo(
            () => getErrorMessage("Gender"),
            [item.Gender]
        );
        const contactPersonNameErrorMessage = useMemo(
            () => getErrorMessage("ContactPersonName"),
            [item.ContactPersonName]
        );
        const relationshipErrorMessage = useMemo(
            () => getErrorMessage("RelationshipType"),
            [item.RelationshipType]
        );
        const bloodGroupErrorMessage = useMemo(
            () => getErrorMessage("RelationshipType"),
            [item.RelationshipType]
        );
        const contactPersonMobileNoErrorMessage = useMemo(
            () => getErrorMessage("ContactPersonMobileNo"),
            [item.ContactPersonMobileNo]
        );
        const employeeGroupErrorMessage = useMemo(
            () => getErrorMessage("EmployeeGroup"),
            [item.EmployeeGroup]
        );
        const emergencyContactPersonErrorMessage = useMemo(
            () => getErrorMessage("EmergencyContactPerson"),
            [item.EmergencyContactPerson]
        );
        const emergencyContactNoErrorMessage = useMemo(
            () => getErrorMessage("EmergencyContactNo"),
            [item.EmergencyContactNo]
        );
        const emergencyRelationshipTypeErrorMessage = useMemo(
            () => getErrorMessage("EmergencyRelationshipType"),
            [item.EmergencyRelationshipType]
        );

        const mergedComments = item.comments
            ?.map(comment => comment.message)
            .join(", ") || "";

        return (
            <tr key={index} className="even:bg-gray-50 odd:bg-white">
                {/* title */}
                <td className="py-2 px-4 border">
                    <Tooltip title={titleErrorMessage} placement="topLeft">
                        <select
                            name="Title"
                            value={item.Title}
                            onChange={(e) => onChange(item.id, "Title", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${titleErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Title</option>
                            {titleEnum.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* first name */}
                <td className="py-2 px-4 border">
                    <Tooltip title={firstNameErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="FirstName"
                            value={item.FirstName}
                            onChange={(e) => onChange(item.id, "FirstName", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${firstNameErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* last name */}
                <td className="py-2 px-4 border">
                    <Tooltip title={lastNameErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="LastName"
                            value={item.LastName}
                            onChange={(e) => onChange(item.id, "LastName", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${lastNameErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* personal email */}
                <td className="py-2 px-4 border">
                    <Tooltip title={personalEmailErrorMessage} placement="topLeft">
                        <input
                            type="email"
                            name="PersonalEmailID"
                            value={item.PersonalEmailID}
                            onChange={(e) =>
                                onChange(item.id, "PersonalEmailID", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${personalEmailErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* personal mobile no */}
                <td className="py-2 px-4 border">
                    <Tooltip title={personalMobileNoErrorMessage} placement="topLeft">
                        <input
                            type="number"
                            name="PersonalMobileNo"
                            value={item.PersonalMobileNo}
                            onChange={(e) =>
                                onChange(item.id, "PersonalMobileNo", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${personalMobileNoErrorMessage
                                ? "border-red-500"
                                : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* professional email id */}
                <td className="py-2 px-4 border">
                    <Tooltip title={ProfessionalEmailErrorMessage} placement="topLeft">
                        <input
                            type="email"
                            name="ProfessionalEmailID"
                            value={item.ProfessionalEmailID}
                            onChange={(e) =>
                                onChange(item.id, "ProfessionalEmailID", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${ProfessionalEmailErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* department */}
                <td className="py-2 px-4 border">
                    <Tooltip title={departmentErrorMessage} placement="topLeft">
                        <select
                            name="Department"
                            value={item.Department}
                            onChange={(e) => onChange(item.id, "Department", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${departmentErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Department</option>
                            {departmentlist.map((option) => (
                                <option key={option.id} value={option.department_name}>
                                    {option.department_name}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* designation */}
                <td className="py-2 px-4 border">
                    <Tooltip title={designationErrorMessage} placement="topLeft">
                        <select
                            name="Designation"
                            value={item.Designation}
                            onChange={(e) => onChange(item.id, "Designation", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${designationErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Designation</option>
                            {designationList.map((option) => (
                                <option key={option.id} value={option.designation_name}>
                                    {option.designation_name}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* employee Type */}
                <td className="py-2 px-4 border">
                    <Tooltip title={employeeTypeErrorMessage} placement="topLeft">
                        <select
                            name="EmployeeType"
                            value={item.EmployeeType}
                            onChange={(e) =>
                                onChange(item.id, "EmployeeType", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${employeeTypeErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Designation</option>
                            {eType.map((option) => (
                                <option key={option.id} value={option.employee_type_name}>
                                    {option.employee_type_name}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* Date Of Joining */}
                <td className="py-2 px-4 border">
                    <Tooltip title={dojErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="DateOfJoining"
                            value={parseAndFormatDate(item.DateOfJoining)}
                            onChange={(e) => {
                                const formattedDate = parseAndFormatDate(e.target.value);
                                onChange(item.id, "DateOfJoining", formattedDate);
                            }}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${dojErrorMessage ? "border-red-500" : "border-gray-300"}`}
                        />
                    </Tooltip>
                </td>


                {/* shift */}
                <td className="py-2 px-4 border">
                    <Tooltip title={shiftErrorMessage} placement="topLeft">
                        <select
                            name="Shift"
                            value={item.Shift}
                            onChange={(e) => onChange(item.id, "Shift", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${shiftErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Shift</option>
                            {eShift.map((option) => (
                                <option key={option.id} value={option.shift_name}>
                                    {option.shift_name}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* City */}
                <td className="py-2 px-4 border">
                    <Tooltip title={cityErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="City"
                            value={item.City}
                            onChange={(e) =>
                                onChange(item.id, "City", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${cityErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* State */}
                <td className="py-2 px-4 border">
                    <Tooltip title={stateErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="State"
                            value={item.State}
                            onChange={(e) =>
                                onChange(item.id, "State", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${stateErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* pincode */}
                <td className="py-2 px-4 border">
                    <Tooltip title={pincodeErrorMessage} placement="topLeft">
                        <input
                            type="number"
                            name="Pincode"
                            value={item.Pincode}
                            onChange={(e) =>
                                onChange(item.id, "Pincode", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${pincodeErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* presentAddressCity */}
                <td className="py-2 px-4 border">
                    <Tooltip title={presentAddressCityErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="PresentAddressCity"
                            value={item.PresentAddressCity}
                            onChange={(e) =>
                                onChange(item.id, "PresentAddressCity", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${presentAddressCityErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* presentAddressState */}
                <td className="py-2 px-4 border">
                    <Tooltip title={presentAddressStateErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="PresentAddressState"
                            value={item.PresentAddressState}
                            onChange={(e) =>
                                onChange(item.id, "PresentAddressState", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${presentAddressStateErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* presentAddressPincode */}
                <td className="py-2 px-4 border">
                    <Tooltip title={presentAddressPincodeErrorMessage} placement="topLeft">
                        <input
                            type="number"
                            name="PresentAddressPincode"
                            value={item.PresentAddressPincode}
                            onChange={(e) =>
                                onChange(item.id, "PresentAddressPincode", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${presentAddressPincodeErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* Usergroup */}
                <td className="py-2 px-4 border">
                    <Tooltip title={userGroupErrorMessage} placement="topLeft">
                        <select
                            name="Usergroup"
                            value={item.Usergroup}
                            onChange={(e) => onChange(item.id, "Usergroup", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${userGroupErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select User group</option>
                            {userGroup.map((option) => (
                                <option key={option.id} value={option.user_group_name}>
                                    {option.user_group_name}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* Username */}
                {/* <td className="py-2 px-4 border">
                    <Tooltip title={usernameErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="Username"
                            value={item.Username}
                            onChange={(e) =>
                                onChange(item.id, "Username", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${usernameErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td> */}

                {/* Password */}
                {/* <td className="py-2 px-4 border">
                    <Tooltip title={passwordErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="Password"
                            value={item.Password}
                            onChange={(e) =>
                                onChange(item.id, "Password", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${passwordErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td> */}

                {/* gender */}
                <td className="py-2 px-4 border">
                    <Tooltip title={genderErrorMessage} placement="topLeft">
                        <select
                            name="Gender"
                            value={item.Gender}
                            onChange={(e) => onChange(item.id, "Gender", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${genderErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Gender</option>
                            {genderEnum.map((option) => (
                                <option key={option.id} value={option.text}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* ContactPersonName */}
                <td className="py-2 px-4 border">
                    <Tooltip title={contactPersonNameErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="ContactPersonName"
                            value={item.ContactPersonName}
                            onChange={(e) =>
                                onChange(item.id, "ContactPersonName", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${contactPersonNameErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* RelationshipType */}
                <td className="py-2 px-4 border">
                    <Tooltip title={relationshipErrorMessage} placement="topLeft">
                        <select
                            name="RelationshipType"
                            value={item.RelationshipType}
                            onChange={(e) => onChange(item.id, "RelationshipType", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${relationshipErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Relationship</option>
                            {relationshipEnum.map((option) => (
                                <option key={option.id} value={option.text}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* ContactPersonMobileNo */}
                <td className="py-2 px-4 border">
                    <Tooltip title={contactPersonMobileNoErrorMessage} placement="topLeft">
                        <input
                            type="number"
                            name="ContactPersonMobileNo"
                            value={item.ContactPersonMobileNo}
                            onChange={(e) =>
                                onChange(item.id, "ContactPersonMobileNo", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${contactPersonMobileNoErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* EmployeeGroup */}
                <td className="py-2 px-4 border">
                    <Tooltip title={employeeGroupErrorMessage} placement="topLeft">
                        <select
                            name="EmployeeGroup"
                            value={item.EmployeeGroup}
                            onChange={(e) => onChange(item.id, "EmployeeGroup", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${employeeGroupErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Relationship</option>
                            {employeeGroupList.map((option) => (
                                <option key={option.id} value={option.employee_group_name}>
                                    {option.employee_group_name}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* BloodGroup */}
                <td className="py-2 px-4 border">
                    <Tooltip title={bloodGroupErrorMessage} placement="topLeft">
                        <select
                            name="BloodGroup"
                            value={item.BloodGroup}
                            onChange={(e) => onChange(item.id, "BloodGroup", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${bloodGroupErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Blood Group</option>
                            {bloodGroupEnum.map((option) => (
                                <option key={option.id} value={option.text}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {/* EmergencyContactPerson */}
                <td className="py-2 px-4 border">
                    <Tooltip title={emergencyContactPersonErrorMessage} placement="topLeft">
                        <input
                            type="text"
                            name="EmergencyContactPerson"
                            value={item.EmergencyContactPerson}
                            onChange={(e) =>
                                onChange(item.id, "EmergencyContactPerson", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${emergencyContactPersonErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>

                {/* EmergencyContactNo */}
                <td className="py-2 px-4 border">
                    <Tooltip title={emergencyContactNoErrorMessage} placement="topLeft">
                        <input
                            type="number"
                            name="EmergencyContactNo"
                            value={item.EmergencyContactNo}
                            onChange={(e) =>
                                onChange(item.id, "EmergencyContactNo", e.target.value)
                            }
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${emergencyContactNoErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        />
                    </Tooltip>
                </td>


                {/* EmergencyRelationshipType */}
                <td className="py-2 px-4 border">
                    <Tooltip title={emergencyRelationshipTypeErrorMessage} placement="topLeft">
                        <select
                            name="EmergencyRelationshipType"
                            value={item.EmergencyRelationshipType}
                            onChange={(e) => onChange(item.id, "EmergencyRelationshipType", e.target.value)}
                            className={`w-full px-2 py-1 border rounded focus:outline-none focus:ring focus:ring-blue-200 ${emergencyRelationshipTypeErrorMessage ? "border-red-500" : "border-gray-300"
                                }`}
                        >
                            <option value="">Select Relationship</option>
                            {relationshipEnum.map((option) => (
                                <option key={option.id} value={option.text}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </td>

                {migratekey ?
                    <td className="py-2 px-4 border">
                        <Tooltip title={mergedComments}>
                            <p>{mergedComments?.slice(0, 15) + "..."}</p>
                        </Tooltip>
                    </td>
                    : null}

                {/* delete */}
                <td className="py-2 px-4 border">
                    <Tooltip title={"Delete"}>
                        <IconButton
                            title="Delete"
                            icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                            onClick={() => (handleDeleteClicked(index))}
                            testID={'ug_btn_delete'}
                        />
                    </Tooltip>
                </td>
            </tr>
        );
    }, areEqual
);

export default Item;
