import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Card, Spin, Dropdown, Drawer, Select, Modal, Input, Button } from 'antd'
import ToastMsg from "components/common/ToastMsg";
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';
import { EmployeeDetails } from "components/common/CommonComponent";
import { ImagePaths } from "utils/ImagePath";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import MyButton from 'components/ui/Button/MyButton';
import { CommonDeletePopup } from 'components/common/CommonComponent';
import useGetData from "components/common/useGetData";
import { getSubordinateSetupList, getSubordinateCardList } from "hooks/api/configurationApi/payrollPeriodApi";
import { ensureHash } from "components/common/CommonFuntion";
import { SUBORDINATE_SETUP, SUBORDINATE_CARD_LIST } from 'constants';
import { checkUserPermissionFunc } from "components/common/CommonFuntion";

const StyledNode = styled.div`
display: inline-block;
border: 1px solid #D6D6D6;
border-radius: 2px;
width: 14dvw;
height: fit-content;
background-color: #EFEFEF;
white-space: nowrap;
`;

const StyledNodeWoData = styled.div`
display: inline-flex;
justify-content: center;
align-items: center;
border: 1px solid #D6D6D6;
border-radius: 2px;
width: 14dvw;
height: 5dvw;
background-color: transparent;
cursor: pointer;
white-space: nowrap;
`;

/*const initialStructure = {
  card: { title: "", colour: "" },
  employee: {
    id: "",
    title: "",
    employee_id: "",
    employee_name: "",
    profile: "",
    designation: {
      id: "",
      name: ""
    },
    department: {
      id: "",
      name: ""
    }
  },
  is_admin: false,
  is_manager: false,
  subordinates: []
}*/

const OptimizedSubordinate = () => {
    const authtoken = sessionStorage.getItem("token");
    const colorList = ["#D94854", "#0E862A", "#EF7A55", "#616161", "#CACACA"];
    const [isFilterClked, setFilterClked] = useState(false);
    const [filterData, setFilterData] = useState({
        subordinate_designation: "",
        subordinate_department: "",
        subordinate_employee: "",
        subordinate_card: "",
        subordinate_slt_employee: []
    });
    const [dropdownList, setDropDownList] = useState({
        designationList: [],
        departmentlist: [],
        employeeDetailsList: [],
        employeeList: [],
        cardTltList: []
    });
    const [cardCrudDetails, setCardCrudDetails] = useState({
        isOpenAddEdit: false,
        isOptionSelected: false,
        isRemoveClked: false,
        actionType: "",
        cardpopupDetail: { title: "", colour: "" },
    });

    const [loadings, setLoadings] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [saveLoading, setSaveLoading] = useState(false);
    const [nestedStructure, setNestedStructure] = useState({});
    const [empIdList, setEmpIdList] = useState(new Set());
    const [errors, setErrors] = useState({ card: '', employee: '' });
    const [clkedFrom, setClkedFrom] = useState({
        fromInitialNode: false,
        fromLeadNode: false
    })
    const hideCursorCardRef = useRef(null);
    const hideCursorRef = useRef({});

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const userPermission = checkUserPermissionFunc("configuration", "Subordinate", "SubMenu");

    const [data, _message, refetch, loading] = useGetData(getSubordinateSetupList);

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
        if (!Array.isArray(data)) {
            const nestedStruct = convertToNestedStructure(data);
            setNestedStructure({ ...nestedStruct });
        }
    }, [_message.errorMsgText, data]);

    useEffect(() => {
        if (Object.keys(nestedStructure).length > 0) {
            const uniqueEmployees = extractEmployeeSet(nestedStructure);
            setEmpIdList(uniqueEmployees);
        }
    }, [nestedStructure]);

    useEffect(() => {
        if (dropdownList?.employeeDetailsList?.length > 0 && empIdList?.size > 0) {
            // Remove IDs present in empIdList from employeeDetailsList
            const filteredEmpList = [...dropdownList?.employeeDetailsList]?.filter(employee => !empIdList.has(employee?.id));
            // Check if the filtered list is different from the current employeeDetailsList state
            if (filteredEmpList?.length !== dropdownList?.employeeDetailsList?.length) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeList: filteredEmpList
                }));
            }
        }
    }, [dropdownList.employeeDetailsList, empIdList]);

    const convertToNestedStructure = (datas) => {
        // setEmpIdList((PrevEmp) => {
        //   const newSet = new Set(PrevEmp); 
        //   if(datas?.is_active){
        //     newSet.add(datas?.employee?.id);
        //   }
        //   return newSet;
        // });
        return {
            id: datas?.node_id,
            card: datas?.card?.id,
            employee: datas?.employee?.id,
            is_admin: datas?.is_admin,
            is_manager: datas?.is_manager,
            is_active: datas?.is_active,
            subordinates: datas?.subordinates?.length > 0 ?
                datas?.subordinates?.map(subordinate => convertToNestedStructure(subordinate)) : []
        };
    };

    const extractEmployeeSet = (node) => {
        const employeeSet = new Set();
        const traverse = (node) => {
            if (node?.is_active) {
                employeeSet?.add(node?.employee);
            }
            if (node?.subordinates && node?.subordinates?.length > 0) {
                node?.subordinates.forEach(subordinate => traverse(subordinate));
            }
        }
        traverse(node);
        return employeeSet;
    }

    const getEmployeeDesignationList = async () => {
        try {
            const apiData = await getEmployeeDesignation(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    designationList: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    designationList: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                designationList: []
            }))
        }
    }

    const getDepartmentList = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    departmentlist: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    departmentlist: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                departmentlist: []
            }))
        }
    }

    const getEmployeeNameList = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData?.status === "success" && apiData?.data) {
                const activeEmployees = apiData?.data?.filter(employee => employee?.is_active === true);
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeDetailsList: [...activeEmployees]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeDetailsList: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                employeeDetailsList: []
            }))
        }
    };

    const getCardList = async () => {
        try {
            const apiData = await getSubordinateCardList(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    cardTltList: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    cardTltList: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                cardTltList: []
            }))
        }
    };

    useEffect(() => {
        getDepartmentList();
        getEmployeeDesignationList();
        getEmployeeNameList();
        getCardList();
    }, [])


    // drawer starts
    const handleChange = (statekey, value) => {
        setFilterData((prevState) => ({
            ...prevState,
            [statekey]: value
        }))
    }

    // card tilte popup starts 
    const handleAddEdit = (type) => {
        const cardDetails = dropdownList?.cardTltList?.length > 0 ?
            dropdownList?.cardTltList?.find(item => item.id === filterData.subordinate_card) : {};
        setCardCrudDetails((prevState) => ({
            ...prevState,
            isOpenAddEdit: type !== "remove",
            actionType: type,
            cardpopupDetail: type !== "add" ? cardDetails : { title: "", colour: "" },
            isRemoveClked: type === "remove"
        }));
    }

    const handleCardCls = () => {
        setCardCrudDetails({
            isOpenAddEdit: false,
            isOptionSelected: false,
            isRemoveClked: false,
            actionType: "",
            cardpopupDetail: { title: "", colour: "" },
        });
        setFilterData((prevState) => ({
            ...prevState,
            subordinate_card: ""
        }));
        setLoadings(false);
    }

    const handleCardCreate = useCallback((isRemove) => {
        let { cardpopupDetail, actionType } = cardCrudDetails;
        let methods = actionType !== "add" ? "PUT" : "POST";
        try {
            setLoadings(true);
            let updatedFormData = {
                id: cardpopupDetail?.id,
                title: cardpopupDetail?.title,
                colour: cardpopupDetail?.colour,
                is_active: isRemove ? false : true
            };
            fetch(SUBORDINATE_CARD_LIST, {
                method: methods,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        setLoadings(false);
                        refetch();
                        handleCardCls();
                        getCardList();
                        ToastMsg("success", data.message);
                    }
                    else if (data.status === "fail") {
                        ToastMsg("error", data.message);
                        setLoadings(false);
                    }
                })
                .catch(error => {
                    error.json().then(data => {
                        setLoadings(false);
                        ToastMsg("error", data);
                    });
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [cardCrudDetails.cardpopupDetail]);

    const renderCardSelect = () => {
        return (
            <div style={{ margin: "5px" }}>
                <div className='commonInputTlt'>Select Card <span className='requiredTxt'>*</span></div>
                <Select
                    showSearch
                    style={{ outline: "none", width: "100%" }}
                    value={filterData.subordinate_card ? filterData.subordinate_card : ""}
                    name={"subordinate_card"}
                    onChange={(value) => {
                        setFilterData((prevState) => ({
                            ...prevState,
                            subordinate_card: value
                        }));
                        setCardCrudDetails((prevState) => ({
                            ...prevState,
                            isOptionSelected: value ? true : false,
                        }));
                        setErrors((prevState) => ({
                            ...prevState,
                            card: ""
                        }))
                    }}
                    dropdownRender={menu => (
                        <div>
                            {menu}
                            <div
                                className='ant-select-item'
                                onMouseDown={e => e.preventDefault()}
                                onClick={() => handleAddEdit("add")}
                            >
                                Add Card Title
                            </div>
                            {cardCrudDetails.isOptionSelected && (
                                <>
                                    <div className="ant-select-item" onClick={() => handleAddEdit("edit")}>
                                        Edit Card Title
                                    </div>
                                    <div className="ant-select-item" onClick={() => handleAddEdit("remove")}>
                                        Remove Card Title
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    ref={hideCursorCardRef}
                    onSelect={() => {
                        hideCursorCardRef.current.blur();
                    }}
                >
                    {dropdownList?.cardTltList?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                    ))}
                </Select>
                {errors.card && <p className='requiredTxt' style={{ fontSize: "0.85dvw" }}>{errors.card}</p>}
            </div>
        )
    }

    const handleCardValChange = (statekey, value) => {
        setCardCrudDetails((prevState) => ({
            ...prevState,
            cardpopupDetail: {
                ...prevState.cardpopupDetail,
                [statekey]: value
            }
        }))
    }

    // card tilte popup ends

    const renderSelect = (label, statekey, list = [], showKey = "text", valueKey = "value", extrakey = "employee_id", isExtraDetail = false) => {
        if (!hideCursorRef.current[statekey]) {
            hideCursorRef.current[statekey] = React.createRef();
        }
        return (
            <div style={{ margin: "5px" }}>
                <div className='commonInputTlt'>{label}</div>
                <Select
                    showSearch
                    style={{ outline: "none", width: "100%" }}
                    value={filterData[statekey] ? filterData[statekey] : undefined}
                    name={statekey}
                    onChange={(value) => handleChange(statekey, value)}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    ref={hideCursorRef.current[statekey]}
                    onSelect={() => {
                        hideCursorRef.current[statekey].current.blur();
                    }}
                >
                    {list.map((option) => (
                        ((valueKey === "id" && option.is_active === true) || valueKey !== "id") ?
                            <Select.Option key={option[valueKey]} value={option[valueKey]}>
                                {isExtraDetail ? option[showKey] + (option[extrakey] ? " - " + option[extrakey] : "") : option[showKey]}
                            </Select.Option> : null
                    ))}
                </Select>
            </div>
        )
    }

    const filterEmployees = (departmentId, designationId) => {
        let list = dropdownList?.employeeList?.length > 0 ? dropdownList?.employeeList :
            dropdownList?.employeeDetailsList?.length > 0 ? dropdownList?.employeeDetailsList : [];

        return [...list]?.filter(employee => {
            const departmentMatch = !departmentId || employee?.department_id?.[0]?.id === departmentId;
            const designationMatch = !designationId || employee?.designation_id?.[0]?.id === designationId;

            return departmentMatch && designationMatch;
        });
    };

    const filteredEmployees = useMemo(() => {
        return filterEmployees(filterData?.subordinate_department, filterData?.subordinate_designation);
    }, [filterData.subordinate_department, filterData.subordinate_designation, filterEmployees]);

    let emp_details_list = [];
    if (filterData?.subordinate_department || filterData?.subordinate_designation) {
        emp_details_list = filteredEmployees;
    } else {
        emp_details_list = empIdList.size > 0 ? dropdownList?.employeeList : dropdownList?.employeeDetailsList;
    }

    useEffect(() => {
        if (emp_details_list?.length === 1) {
            setFilterData((prevState) => ({
                ...prevState,
                subordinate_slt_employee: [emp_details_list[0]]
            }));
        }
    }, [emp_details_list]);

    const handleClose = () => {
        setFilterClked(false);
        setFilterData({
            subordinate_designation: "",
            subordinate_department: "",
            subordinate_employee: "",
            subOrdinate_card: "",
            subordinate_slt_employee: []
        });
        setSelectedEmployee({});
        setSaveLoading(false);
        setErrors({ card: '', employee: '' })
        setCardCrudDetails({
            isOpenAddEdit: false,
            isOptionSelected: false,
            isRemoveClked: false,
            actionType: "",
            cardpopupDetail: { title: "", colour: "" },
        });
        setErrors({ card: '', employee: '' });
        setClkedFrom(
            {
                fromInitialNode: false,
                fromLeadNode: false
            });
    }

    const updateNestedStructureOnAddemp = (node, nodeId, newEmployees) => {
        // Check if newEmployees is an array
        if (Array.isArray(newEmployees)) {
            // Iterate over each new employee and update the structure
            newEmployees.forEach(newEmployee => {
                node = updateNestedStructureOnAddemp(node, nodeId, newEmployee);
            });
            return node;
        }
        if (node?.id === nodeId) {
            if (node?.is_active) {
                // Add new employee as a subordinate
                return {
                    ...node,
                    subordinates: [...node?.subordinates, newEmployees]
                };
            } else {
                // Update the employee details
                return {
                    ...node,
                    card: newEmployees?.card,
                    employee: newEmployees?.employee,
                    is_admin: newEmployees?.is_admin,
                    is_manager: newEmployees?.is_manager,
                    is_active: true,
                    subordinates: newEmployees?.subordinates
                };
            }
        } else if (node?.subordinates && node?.subordinates?.length > 0) {
            // Recursively search in subordinates
            return {
                ...node,
                subordinates: node?.subordinates?.map(subNode =>
                    updateNestedStructureOnAddemp(subNode, nodeId, newEmployees)
                )
            };
        } else {
            return node;
        }
    };

    const updateNestedStructure = (node, nodeId, updatedEmployeeDetails) => {
        if (node.id === nodeId) {
            // Update the employee details of the matched node
            return { ...node, ...updatedEmployeeDetails };
        } else if (node.subordinates && node.subordinates.length > 0) {
            // Recursively update the subordinates if present
            return {
                ...node,
                subordinates: node.subordinates.map(subNode =>
                    updateNestedStructure(subNode, nodeId, updatedEmployeeDetails)
                )
            };
        } else {
            // Return the node as is if it doesn't match the nodeId
            return node;
        }
    };

    const handleSave = useCallback((employeeDetails = {}, action = "add_employee") => {
        try {
            let updateStructure = {};
            let { subordinate_slt_employee, subordinate_card } = filterData;
            let tempErrors = {};

            if (!subordinate_card) {
                tempErrors.card = 'This field is required';
            }

            if (subordinate_slt_employee?.length === 0) {
                tempErrors.employee = 'This field is required';
            }

            // If there are errors, update the state and return early
            if (Object.keys(tempErrors)?.length > 0 && employeeDetails?.id === undefined) {
                setErrors((prevState) => ({
                    ...prevState,
                    ...tempErrors
                }));
                return;
            } else {
                setErrors({ card: '', employee: '' });
                setSaveLoading(true);
                if (Array.isArray(data) && Object.keys(selectedEmployee)?.length === 0) {
                    // initial employee onboard of the company;
                    updateStructure = {
                        card: subordinate_card,
                        employee: subordinate_slt_employee[0]?.id,
                        is_admin: true,
                        is_manager: true,
                        is_active: true,
                        subordinates: []
                    }
                } else if (Object.keys(employeeDetails)?.length > 0 && Object.keys(selectedEmployee)?.length === 0) {
                    updateStructure = updateNestedStructure(nestedStructure, employeeDetails?.id, employeeDetails);
                } else if (Object.keys(selectedEmployee).length > 0) {
                    let newEmployees = subordinate_slt_employee?.map(emp => ({
                        card: subordinate_card,
                        employee: emp?.id,
                        is_admin: false,
                        is_manager: false,
                        is_active: true,
                        subordinates: []
                    }));

                    if (selectedEmployee?.is_manager && selectedEmployee?.subordinates?.length > 0) {
                        const nestedSubordinate = selectedEmployee?.subordinates?.map(convertToNestedStructure);
                        if (clkedFrom?.fromInitialNode) {
                            newEmployees = newEmployees?.map(emp => ({
                                ...emp,
                                is_admin: true,
                                is_manager: true,
                                subordinates: [...nestedSubordinate]
                            }));
                        } else if (clkedFrom?.fromLeadNode) {
                            newEmployees = newEmployees?.map(emp => ({
                                ...emp,
                                is_manager: true,
                                subordinates: [...nestedSubordinate]
                            }));
                        }
                    }
                    // Update the nested structure with the new employee details
                    updateStructure = updateNestedStructureOnAddemp(nestedStructure, selectedEmployee?.node_id, newEmployees);
                }
                updateStructure = {
                    ...updateStructure,
                    action
                }
                fetch(SUBORDINATE_SETUP, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${authtoken}`
                    },
                    body: JSON.stringify(updateStructure)
                })
                    .then((response) => response.json())
                    .then(async data => {
                        if (data.status === "success") {
                            setSaveLoading(false);
                            handleClose();
                            refetch();
                            ToastMsg("success", data.message);
                        }
                        else if (data.status === "fail") {
                            ToastMsg("error", data.message);
                            setSaveLoading(false);
                        }
                    })
                    .catch(error => {
                        error.json().then(data => {
                            setSaveLoading(false);
                            ToastMsg("error", data);
                        });
                    });
            }
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [dropdownList, filterData]);
    // drawer ends

    const handleEmployee = (key, records = {}) => {
        if (key === "1") {
            // add employee
            setSelectedEmployee(records);
            setFilterClked(true);
        } else if (key === "2") {
            // remove employee
            const nestedSubordinate = records?.subordinates?.length > 0 ? records?.subordinates?.map(convertToNestedStructure)
                : [];
            const updateData = {
                id: records?.node_id,
                card: records?.card?.id,
                employee: records?.employee?.id,
                is_admin: records?.is_admin,
                is_manager: records?.is_manager,
                is_active: false,
                subordinates: [...nestedSubordinate]
            };
            handleSave(updateData, "remove_employee");
        } else if (key === "3") {
            // Add as a team lead
            const nestedSubordinate = records?.subordinates?.length > 0 ? records?.subordinates?.map(convertToNestedStructure)
                : [];
            const updateData = {
                id: records?.node_id,
                card: records?.card?.id,
                employee: records?.employee?.id,
                is_admin: records?.is_admin,
                is_manager: true,
                is_active: records?.is_active,
                subordinates: [...nestedSubordinate]
            };
            handleSave(updateData, "add_as_a_team_lead");
        } else if (key === "4") {
            // Remove as a team lead
            const nestedSubordinate = records?.subordinates?.length > 0 ? records?.subordinates?.map(convertToNestedStructure)
                : [];
            const updateData = {
                id: records?.node_id,
                card: records?.card?.id,
                employee: records?.employee?.id,
                is_admin: records?.is_admin,
                is_manager: false,
                is_active: records?.is_active,
                subordinates: [...nestedSubordinate]
            };
            handleSave(updateData, "remove_as_a_team_lead");
        } else if (key === "5") {
            // add employee of removed team lead;
            setSelectedEmployee(records);
            setFilterClked(true);
            setClkedFrom((prevState) => ({
                ...prevState,
                fromLeadNode: true
            }))
        } else if (key === "6") {
            // add employee of removed initial node;
            setSelectedEmployee(records);
            setFilterClked(true);
            setClkedFrom((prevState) => ({
                ...prevState,
                fromInitialNode: true
            }))
        }
    }

    const hasActiveDescendants = (node) => {
        if (!node?.subordinates || node?.subordinates?.length === 0) {
            return false;
        }
        return node?.subordinates?.some(sub => sub?.is_active || hasActiveDescendants(sub));
    };

    const renderTreeNodes = (nodes) => {
        return nodes?.map((node) => {
          const hasActiveSubordinates = node?.subordinates && node?.subordinates?.some(sub => sub?.is_active || hasActiveDescendants(sub));
            const shouldRenderNode = node?.is_active || hasActiveSubordinates;
    
            // If the node shouldn't be rendered, return null
            if (!shouldRenderNode) {
                return null;
            }
    
          const items = [{
            key: "2",
            label: (<div key = {"2"} className="empApproveTxt">Remove Employee</div>),
          }
          ];
          if (node?.is_manager) {
            if(emp_details_list?.length !== 0){
            items.unshift({
              key: "1",
              label: (<div key = {"1"} className="empApproveTxt">Add Employee</div>),
            });}
            items.push({
              key: "4",
              label: (<div key = {"4"} className="empApproveTxt">Remove As A Team Lead</div>),
            })
          } else {
            items.splice(2, 0, {
              key: "3",
              label: (<div key = {"3"} className="empApproveTxt">Add As A Team Lead</div>),
            })
          }
    
          const label = node?.is_active ? <StyledNode>
            <div className="subordinate_cardTxt"
              style={{
                backgroundColor: node?.card?.colour ? ensureHash(node?.card?.colour) : "transparent",
                display: node?.card?.colour ? "flex" : "none"
              }}>
              {node?.card?.title ? node?.card?.title : ""}
              {node?.is_manager ?
                <img src={ImagePaths.teamLead.default} alt="teamlead" className='commonTblBackIcon' style={{ marginLeft: "10px" }} />
                : null}
            </div>
            <div className='flex justify-between items-center p-1'>
              <EmployeeDetails details={{
                ...node?.employee,
                "title": node?.employee?.title ? node?.employee?.title : "",
                "profile": node?.employee?.profile ? node?.employee?.profile : "",
                "designation_id": node?.employee?.designation ?
                  { id: node?.employee?.designation?.id, designation_name: node?.employee?.designation?.name }
                  : { id: 0, designation_name: "" },
                "first_name": node?.employee?.employee_name ? node?.employee?.employee_name : "",
                "id": ""
              }}/>
              {userPermission?.edit ?
                <Dropdown
                  menu={{
                    items,
                    onClick: (e) => handleEmployee(e.key, node)
                  }} trigger={['click']} placement="bottomLeft">
                  <img src={ImagePaths.moreIcon.default} alt="more" className='subEmployee_moreIcon' />
                </Dropdown> : null}
            </div>
          </StyledNode>
            : (!node?.is_active && node?.subordinates && node?.subordinates?.length > 0) ?
              <StyledNodeWoData onClick={() => handleEmployee("5", node)}>
                <img src={ImagePaths.addOff.default} alt="addIcon" className='subordinate_wo_add_icon' />
                <p className="subEmployee_details_content">Add Employee</p>
              </StyledNodeWoData> : null;
          return label ? (
            <TreeNode key={node?.id} label={label}>
              {node?.subordinates && node?.subordinates?.length > 0 ? renderTreeNodes(node?.subordinates) : null}
            </TreeNode>
          ) : null;
        }).filter(Boolean);
      };

    let AdminItems = [
        {
            key: "2",
            label: (<div className="empApproveTxt">Remove Employee</div>),
        }];

    if (emp_details_list?.length !== 0) {
        AdminItems?.unshift({
            key: "1",
            label: (<div className="empApproveTxt">Add Employee</div>),
        })
    }

    const handleFltClk = (item) => {
        setFilterData((prevState) => {
            const { subordinate_slt_employee } = prevState;
            const uniqueSltEmployee = new Set(subordinate_slt_employee);
            const itemExists = uniqueSltEmployee.has(item);
            if (clkedFrom.fromInitialNode || clkedFrom.fromLeadNode) {
                // Single selection logic
                if (itemExists) {
                    uniqueSltEmployee.clear();;
                } else {
                    uniqueSltEmployee.clear();
                    uniqueSltEmployee.add(item);
                }
            } else {
                // Multiple selection logic
                if (itemExists) {
                    uniqueSltEmployee.delete(item);
                } else {
                    uniqueSltEmployee.add(item);
                }
            }

            return {
                ...prevState,
                subordinate_slt_employee: [...uniqueSltEmployee]
            };
        })
        setErrors((prevState) => ({
            ...prevState,
            employee: ""
        }))
    }

    const rootLabelRef = useRef(null); // Create a ref for the root label

    useEffect(() => {
        if (rootLabelRef.current) {
            // Scroll the root label into view and center it
            rootLabelRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    }, [data, loading]);

    return (
        <Card style={{ width: "100%", height: "100%" }}>
            {loading && (
                <div className="loaderOverlay">
                    <Spin />
                </div>
            )}
            <div className='runpayRoll_Txt border-b flex justify-between items-center' >
                <span>{`Subordinate Setup ${empIdList?.size > 0 ? `(${empIdList?.size})` : ""}`}</span>
            </div>
            <div className='sub_hierarchy_container'>
                {!Array.isArray(data) && !loading ?
                    <div className="w-full h-full overflow-auto">
                        <Tree
                            lineWidth={'1px'}
                            lineColor={'#D6D6D6'}
                            lineBorderRadius={'3px'}
                            label={data?.is_active ?
                                <StyledNode>
                                    <div className="subordinate_cardTxt"
                                        style={{
                                            backgroundColor: data?.card?.colour ? ensureHash(data?.card?.colour) : "transparent",
                                            display: data?.card?.colour ? "flex" : "none"
                                        }}>
                                        {data?.card?.title ? data?.card?.title : ""}
                                        {data?.is_manager ?
                                            <img src={ImagePaths.teamLead.default} alt="teamlead" className='commonTblBackIcon' />
                                            : null}
                                    </div>
                                    <div className='flex justify-between items-center p-1' ref={rootLabelRef}>
                                        <EmployeeDetails details={{
                                            ...data?.employee,
                                            "title": data?.employee?.title ? data?.employee?.title : "",
                                            "profile": data?.employee?.profile ? data?.employee?.profile : "",
                                            "designation_id": data?.employee.designation ?
                                                { id: data?.employee.designation.id, designation_name: data?.employee.designation.name }
                                                : { id: 0, designation_name: "" },
                                            "first_name": data?.employee.employee_name ? data?.employee.employee_name : "",
                                            "id": ""
                                        }} />
                                        {userPermission?.edit ?
                                            <Dropdown
                                                menu={{
                                                    items: AdminItems,
                                                    onClick: (e) => handleEmployee(e.key, data)
                                                }} trigger={['click']} placement="bottomLeft">
                                                <img src={ImagePaths.moreIcon.default} alt="more" className='subEmployee_moreIcon' />
                                            </Dropdown> : null}
                                    </div>
                                </StyledNode>
                                :
                                <StyledNodeWoData onClick={() => handleEmployee("6", data)}>
                                    <img src={ImagePaths.addOff.default} alt="addIcon" className='subordinate_wo_add_icon' />
                                    <p className="subEmployee_details_content">Add Employee</p>
                                </StyledNodeWoData>}
                        >
                            {data?.subordinates?.length > 0 ? renderTreeNodes(data?.subordinates) : null}
                        </Tree>
                    </div>
                    :
                    <div className="w-full h-full overflow-auto">
                        <Tree
                            lineWidth={'1px'}
                            lineColor={'#D6D6D6'}
                            lineBorderRadius={'3px'}
                            label={<StyledNodeWoData onClick={() => handleEmployee("1", {})}>
                                <img src={ImagePaths.addOff.default} alt="addIcon" className='subordinate_wo_add_icon' />
                                <p className="subEmployee_details_content">Add Employee</p>
                            </StyledNodeWoData>}
                        />
                    </div>}
            </div>

            {/* Add Employee drawer starts*/}
            <Drawer
                title="Subordinate"
                placement={"right"}
                closable={true}
                onClose={handleClose}
                open={isFilterClked}
                key={"right"}
                maskClosable={true}
                width={"300px"}
                height={"100%"}
                className="custom-drawer"
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div>
                        {renderCardSelect()}
                        {renderSelect("Select Designation", "subordinate_designation", dropdownList.designationList, "designation_name", "id")}
                        {renderSelect("Select Department", "subordinate_department", dropdownList.departmentlist, "department_name", "id")}
                    </div>
                    <div style={{ width: "100%", height: "63%" }}>
                        <p className='subEmployee_detail_txt mt-3' >Employee List <span className='requiredTxt'> *</span></p>
                        {errors.employee && <p className='requiredTxt' style={{ fontSize: "0.85dvw" }}>{errors.employee}</p>}
                        <div className="mt-3" style={{ height: "90%", overflowY: "auto", overflowX: "hidden" }}>
                            {
                                emp_details_list?.length > 0 ?
                                    emp_details_list?.map((item) => {
                                        const isSelected = filterData?.subordinate_slt_employee?.some(emp => emp?.id === item?.id);
                                        return (
                                            <div onClick={() => handleFltClk(item)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: isSelected ? "2px solid #04B7B1" : "none",
                                                    margin: "3px 0px"
                                                }}
                                            >
                                                <EmployeeDetails key={item?.id}
                                                    details={{
                                                        title: item?.title ? item?.title : "",
                                                        first_name: item?.first_name ? item?.first_name : "",
                                                        last_name: item?.last_name ? item?.last_name : "",
                                                        designation_id: item?.designation_id?.length > 0 ?
                                                            { ...item?.designation_id[0] }
                                                            : { id: 0, designation_name: "" },
                                                        id: item?.employee_id
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                    : <div className="w-full h-full flex justify-center items-center subEmployee_details_content">No Data Found</div>
                            }
                        </div>
                    </div>
                    <div className='flex justify-center items-end my-2' style={{ height: "10%" }}>
                        <MyButton label="Cancel" onClick={handleClose} outlined={true} paddingX={"0 0.7dvw"} marginRight={"0.625dvw"} />
                        <MyButton label={"Save"} paddingX={"0 0.5dvw"} onClick={handleSave} loading={saveLoading} />
                    </div>
                </div>
            </Drawer>
            {/* Add Employee drawer ends*/}

            {/* card details Popup starts*/}
            {cardCrudDetails.isOpenAddEdit ?
                <Modal title=""
                    open={true}
                    centered
                    onOk={() => handleCardCreate(false)}
                    onCancel={handleCardCls}
                    width={"30dvw"}
                    className="custom-modal"
                    footer={null}
                >
                    <p className='common_add_tltTxt border-b'>{`${cardCrudDetails.actionType === "edit" ? "Update" : "Create"} Card Title`}</p>
                    <div style={{ margin: "0.625dvw 0dvw" }}>
                        <div className='commonInputTlt'>Enter Card Title</div>
                        <Input
                            autoComplete='off'
                            style={{ width: "100%" }}
                            type="text"
                            name={"title"}
                            value={cardCrudDetails?.cardpopupDetail?.title ? cardCrudDetails?.cardpopupDetail?.title : ""}
                            maxLength={50}
                            onChange={(e) => { handleCardValChange("title", e.target.value) }}
                        />
                    </div>
                    <p className='cardColorTxt'>Select Colour</p>
                    <div className='w-9/12 grid grid-cols-5 m-2'>
                        {colorList.map((color, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "fit-content",
                                    height: "fit-content",
                                    borderRadius: '50%',
                                    border: cardCrudDetails?.cardpopupDetail?.colour === color ? '2px solid #04B7B1' : '2px solid transparent',
                                    boxSizing: 'border-box',
                                }}
                                onClick={() => handleCardValChange("colour", color)}
                            >
                                <Button
                                    key={index}
                                    type="primary"
                                    shape="circle"
                                    size='small'
                                    style={{
                                        backgroundColor: color,
                                        boxShadow: "none",
                                        border: "none",
                                        outline: "none"
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <div className='flex justify-end items-end mt-10 mb-2'>
                        <MyButton key="cancel" label="Cancel" onClick={handleCardCls} outlined={true} paddingX={"0 0.7dvw"} marginRight={"0.625dvw"} />
                        <MyButton key="create" label={cardCrudDetails.actionType === "edit" ? "Update" : "Create"} loading={loadings} onClick={() => handleCardCreate(false)} paddingX={"0 0.5dvw"} />
                    </div>
                </Modal> : null}
            {/* card details Popup ends*/}

            {/* remove card details starts*/}
            {cardCrudDetails.isRemoveClked ?
                <CommonDeletePopup
                    handleCancelBtn={handleCardCls}
                    handleDeleteBtn={() => handleCardCreate(true)}
                    sentence={"Are You Sure ? Do you want to remove this data?"}
                    okBtnLable={"Remove"}
                /> : null}
            {/* remove card details starts*/}
        </Card>
    );
}

export default OptimizedSubordinate
